import SVG from "@client/shared/SVG";
import { withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props extends AttributeStyleProps {
    className?: string;
    icon: string;
    description?: string;
    onClick?: (ev) => void;
}

const Icon: Component<Props> = (props) => {
    const { icon, description, className, ...other } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    // description = $memo(description ?? `${icon} icon`);

    return (
        <SVG
            style={style}
            class={cn(_Icon, classes, className)}
            src={`/assets/resources/static/icons/icon-${icon}.svg`}
            // alt={description}
            {...(rest as any)}
        />
    );
};

const _Icon = css`
    fill: currentColor;
    &.clickable {
        cursor: pointer;
    }

    path {
        fill: currentColor;
    }
`;

export default withStyle(Icon, { SVG: _Icon });
