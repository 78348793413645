import NotFound from "@client/errorPages/NotFound";
import AuthRoute from "@client/routing/AuthRoute";
import SearchbarWithContent from "@client/searchBar/SearchbarWithContent";
import Keyed from "@client/shared/Keyed";
import { UserRole } from "@core/sharedEnums";
import { getRequestEvent, isServer } from "solid-js/web";
import { lazy } from "solid-lazy-plus";

// const SearchbarWithContent = lazy(
//     () => import("@client/searchBar/SearchbarWithContent")
// );

const SentenceDetailsSite = lazy(
    () => import("@client/dictionary/sentence/details/SentenceDetailsSite")
);
const SentenceEditSite = lazy(
    () => import("@client/sentence/SentenceEditSite")
);
const SentenceSearchSite = lazy(
    () => import("@client/dictionary/SearchSite/SentenceSearchSite")
);
const WordDetailsSite = lazy(
    () => import("@client/dictionary/word/details/WordDetailsSite")
);
const SentenceCreateSite = lazy(
    () => import("@client/sentence/SentenceCreateSite.solid")
);
const WordEditSite = lazy(
    () => import("@client/dictionary/word/edit/WordEditSite.solid")
);
const KanjiEditSite = lazy(
    () => import("@client/dictionary/kanji/edit/KanjiEditSite.solid")
);
const WordWritingPracticeSite = lazy(
    () => import("@client/dictionary/practice/WordWritingPracticeSite.solid")
);
const KanjiWritingPracticeSite = lazy(
    () => import("@client/dictionary/practice/KanjiWritingPracticeSite.solid")
);
const HiraKataWritingPracticeSite = lazy(
    () =>
        import("@client/dictionary/practice/HiraKataWritingPracticeSite.solid")
);
const KanjiIndexSite = lazy(
    () => import("@client/dictionary/kanji/index/KanjiIndexSite")
);

const WordIndexSite = lazy(
    () => import("@client/dictionary/word/index/WordIndexSite")
);
const WordSearchSite = lazy(
    () => import("@client/dictionary/SearchSite/WordSearchSite")
);
const KanjiDetailsSite = lazy(
    () => import("@client/dictionary/kanji/details/KanjiDetailsSite")
);
const KanjiSearchSite = lazy(
    () => import("@client/dictionary/SearchSite/KanjiSearchSite")
);
const LessonCreateSite = lazy(() => import("@client/lessons/LessonCreateSite"));
const LessonDetailsSite = lazy(
    () => import("@client/dictionary/lesson/details/LessonDetailsSite")
);
const LessonEditSite = lazy(
    () => import("@client/dictionary/lesson/details/LessonEditSite")
);
const LessonSearchSite = lazy(
    () => import("@client/dictionary/lesson/search/LessonSearchSite")
);

let currentGlobalURL = $signal<URL>(
    isServer ? (null as any) : new URL(window.location.href)
);

if (!isServer) {
    (() => {
        const oldPushState = history.pushState;
        history.pushState = function pushState(...args: any[]) {
            const ret = oldPushState.apply(this, args);
            window.dispatchEvent(new Event("pushstate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        const oldReplaceState = history.replaceState;
        history.replaceState = function replaceState(...args: any[]) {
            const ret = oldReplaceState.apply(this, args);
            window.dispatchEvent(new Event("replacestate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        window.addEventListener("popstate", () => {
            window.dispatchEvent(new Event("locationchange"));
        });
    })();
    window.addEventListener("locationchange", function () {
        currentGlobalURL = new URL(window.location.href);
    });
}

const hasQuery = (name: string) => {
    return !!(
        isServer ? new URL(getRequestEvent()!.request.url) : currentGlobalURL
    ).searchParams.get(name);
};

export const dictionarySite = {
    path: "/dictionary",
    // component: lazy(() => import("@client/dictionary/DictionarySite")),
    // component: (props) => {
    //     const { children } = $destructure(props);
    //     return <SearchbarWithContent>{children}</SearchbarWithContent>;
    // },
    children: [
        {
            path: "/words/*_",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="words">
                    <WordIndexSite {...props} />
                </SearchbarWithContent>
            ),
            matchFilters: {
                _: () => !hasQuery("q") && !hasQuery("jlpt"),
            },
        },
        {
            path: "/:words",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="words">
                    <WordSearchSite {...props} />
                </SearchbarWithContent>
            ),
            matchFilters: {
                _: () => hasQuery("q") || hasQuery("jlpt"),
            },
        },
        {
            path: "/word/:wordId",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="words">
                    <WordDetailsSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/word/:wordId/edit",
            component: (props) => (
                <AuthRoute role={UserRole.Admin}>
                    <SearchbarWithContent activeSearchSubject="words">
                        <WordEditSite {...props} />
                    </SearchbarWithContent>
                </AuthRoute>
            ),
        },
        {
            path: "/word/:wordId/writing-practice",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="words">
                    <WordWritingPracticeSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/kanji/*_",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="kanji">
                    <KanjiIndexSite {...props} />
                </SearchbarWithContent>
            ),
            matchFilters: {
                _: () => !hasQuery("q") && !hasQuery("jlpt"),
            },
        },
        {
            path: "/kanji/*_",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="kanji">
                    <KanjiSearchSite {...props} />
                </SearchbarWithContent>
            ),
            matchFilters: {
                _: () => hasQuery("q") || hasQuery("jlpt"),
            },
        },
        {
            path: "/kanji/:kanjiId",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="kanji">
                    <KanjiDetailsSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/kanji/:kanjiId/edit",
            component: (props) => (
                <AuthRoute role={UserRole.Admin}>
                    <SearchbarWithContent activeSearchSubject="kanji">
                        <KanjiEditSite {...props} />
                    </SearchbarWithContent>
                </AuthRoute>
            ),
        },
        {
            path: "/kanji/:kanjiId/writing-practice",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="lessons">
                    <KanjiWritingPracticeSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/sentences",
            component: () => (
                <SearchbarWithContent activeSearchSubject="sentences">
                    <SentenceSearchSite />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/sentence/:id/edit",
            component: (props) => (
                <AuthRoute role={UserRole.Admin}>
                    <SearchbarWithContent activeSearchSubject="sentences">
                        <SentenceEditSite {...props} />
                    </SearchbarWithContent>
                </AuthRoute>
            ),
        },
        {
            path: "/sentence/:idOrSlug",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="sentences">
                    <SentenceDetailsSite {...props} />
                </SearchbarWithContent>
            ),
        },

        {
            path: "/new/sentence",
            component: (props) => (
                <AuthRoute role={UserRole.Admin}>
                    <SearchbarWithContent activeSearchSubject="sentences">
                        <SentenceCreateSite {...props} />
                    </SearchbarWithContent>
                </AuthRoute>
            ),
        },
        {
            path: "/lessons",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="lessons">
                    <LessonSearchSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/lesson/:idOrSlug",
            component: (props) => {
                return (
                    <SearchbarWithContent activeSearchSubject="lessons">
                        <Keyed key={props.location.pathname}>
                            <LessonDetailsSite {...props} />
                        </Keyed>
                    </SearchbarWithContent>
                );
            },
        },
        {
            path: "/lesson/:idOrSlug/edit",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="lessons">
                    <LessonEditSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/new/lesson",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="lessons">
                    <LessonCreateSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/hiragana-katakana-writing-practice/:character",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="lessons">
                    <HiraKataWritingPracticeSite {...props} />
                </SearchbarWithContent>
            ),
        },
        //     //             {/*<Route path="/dictionary/kanji/:kanjiId/writing-practice" exact={true}*/}
        //     //             {/*    render={(p) =>*/}
        //     //             {/*        <NoSSR>*/}
        //     //             {/*            <LazyComponent component={KanjiWritingPracticeSite} {...p}/>*/}
        //     //             {/*        </NoSSR>*/}
        //     //             {/*    }/>*/}
        {
            path: "*",
            component: () => <NotFound />,
        },
    ],
};

// const DictionarySite: Component = (props: any) => {
//     const { children } = $destructure(props);
//     //     const q = $derefSignal(useQueryParam("q", "string"));
//     //     const jlpt = $derefSignal(useQueryParam("jlpt", "string"));
//     return (
//         <>
//             <SearchbarWithContent activeSearchSubject="words">
//                 {children}
//             </SearchbarWithContent>
//         </>
//     );
//     //
//     //     return (
//     //         <Routes>
//     //             <Route
//     //                 path="/word/:wordId"
//     //                 component={() => (
//     //                     <SearchbarWithContent activeSearchSubject="words">
//     //                         <WordDetailsSite />
//     //                     </SearchbarWithContent>
//     //                 )}
//     //             />
//     //
//     //
//     //             {/*<Route path="/dictionary/words" exact={true}*/}
//     //             {/*    render={(p) =>*/}
//     //             {/*        <SearchbarWithContent activeSearchSubject="words" {...p}>*/}
//     //             {/*            {(q == null && jlpt == null) && <>*/}
//     //             {/*                <WordIndexSite/>*/}
//     //             {/*            </> || <>*/}
//     //             {/*                <NoSSR>*/}
//     //             {/*                    <LazyComponent component={WordSearchSite} blockLoading {...p}/>*/}
//     //             {/*                </NoSSR>*/}
//     //             {/*            </>}*/}
//     //             {/*        </SearchbarWithContent>}*/}
//     //             {/*/>*/}
//     //             <Route
//     //                 path="/words"
//     //                 component={() => (
//     //                     <>
//     //                         <SearchbarWithContent>
//     //                             <Switch>
//     //                                 <Match when={q == null && jlpt == null}>
//     //                                     <WordIndexSite />
//     //                                 </Match>
//     //                                 <Match when={true}>
//     //                                     <WordSearchSite />
//     //                                 </Match>
//     //                             </Switch>
//     //                         </SearchbarWithContent>
//     //                     </>
//     //                 )}
//     //             />
//     //
//     //             {/*<AuthRoute*/}
//     //             {/*    path="/dictionary/words/edit" exact={true}*/}
//     //             {/*    role={UserRole.Admin}*/}
//     //             {/*    render={(p) =>*/}
//     //             {/*        <SearchbarWithContent activeSearchSubject="words" {...p}>*/}
//     //             {/*            <NoSSR>*/}
//     //             {/*                <WordIndexEditSite/>*/}
//     //             {/*            </NoSSR>*/}
//     //             {/*        </SearchbarWithContent>}*/}
//     //             {/*/>*/}
//     //
//     //             <Route
//     //                 path="/kanji/:kanjiId"
//     //                 component={() => (
//     //                     <>
//     //                         <SearchbarWithContent activeSearchSubject="kanji">
//     //                             <KanjiDetailsSite />
//     //                         </SearchbarWithContent>
//     //                     </>
//     //                 )}
//     //             />
//     //
//     //             {/*<AuthRoute path="/dictionary/kanji/:kanjiId/edit" exact={true}*/}
//     //             {/*    role={UserRole.Admin} type="usersOnly"*/}
//     //             {/*    render={(p) =>*/}
//     //             {/*        <SearchbarWithContent activeSearchSubject="words" wide>*/}
//     //             {/*            <NoSSR>*/}
//     //             {/*                <LazyComponent component={KanjiEditSite} {...p}/>*/}
//     //             {/*            </NoSSR>*/}
//     //             {/*        </SearchbarWithContent>}*/}
//     //             {/*/>*/}
//     //
//     //             <Route
//     //                 path="/kanji"
//     //                 component={() => (
//     //                     <>
//     //                         <SearchbarWithContent activeSearchSubject="kanji">
//     //                             <Switch>
//     //                                 <Match when={q == null && jlpt == null}>
//     //                                     <KanjiIndexSite />
//     //                                 </Match>
//     //                                 <Match when={true}>
//     //                                     <KanjiSearchSite />
//     //                                 </Match>
//     //                             </Switch>
//     //                         </SearchbarWithContent>
//     //                     </>
//     //                 )}
//     //             />
//     //
//     //             {/*<Route path="/new/lesson" component={() => <LessonSite/>}/>*/}
//     //             {/*<Route path="/lesson*" component={() => <LessonSite/>}/>*/}
//     //             {/*<Route path="/lessons" component={() => <LessonSite/>}/>*/}
//     //             <LessonSite />
//     //
//     //
//     //
//     //
//     //             {/*<Route path="/dictionary/sentences" exact={true}*/}
//     //             {/*    render={(p) =>*/}
//     //             {/*        <SearchbarWithContent activeSearchSubject="sentences" {...p}>*/}
//     //             {/*            <NoSSR>*/}
//     //             {/*                <LazyComponent component={SentenceSearchSite} blockLoading {...p}/>*/}
//     //             {/*            </NoSSR>*/}
//     //             {/*        </SearchbarWithContent>}*/}
//     //             {/*/>*/}
//     //
//     //             <Route
//     //                 path="*"
//     //                 component={() => (
//     //                     <SearchbarWithContent>
//     //                         <NotFound />
//     //                     </SearchbarWithContent>
//     //                 )}
//     //             />
//     //         </Routes>
//     //     );
// };

// export default DictionarySite;
