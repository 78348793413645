import {
    bodyText,
    breakpoint,
    contentContainer,
    heading1Text,
    sizeXS,
    text,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";

export const TextContainer = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${contentContainer}
    margin-top: 32px;
    margin-bottom: 32px;
`;

export const ErrorBanner = css`
    ${text("primaryBold", "jumbo", "link-primary-600")}
    margin-top: 64px;

    ${sizeXS} {
        align-self: flex-start;
        ${heading1Text}
    }
`;

export const ErrorBannerSub = css`
    text-align: center;
    ${text("primaryBold", "big", "link-primary-600")}
`;

export const ErrorReason = css`
    text-align: center;
    ${text("primary", "heading3", "colors/text-600a")}
    margin-top: 8px;
    ${breakpoint("xs", "m")} {
        text-align: left;
        ${bodyText}
    }
`;

export const HomeButton = css`
    margin: 32px 0 0;
`;
