import { HTMLProps } from "@core/util/solid/HTMLProps.solid";
import { useNavigate } from "@solidjs/router";
import { Component, JSX } from "solid-js";

interface Props extends HTMLProps<"a"> {
    children?: JSX.Element;
    to: string;
    className?: string;
}

const RouterLink: Component<Props> = (props) => {
    const { children, to, onClick, className, ...rest } = $destructure(props);

    const navigate = useNavigate();

    const handleClick: JSX.EventHandler<HTMLAnchorElement, MouseEvent> = (
        ev
    ) => {
        if (typeof onClick == "function") {
            onClick(ev);
            if (ev.defaultPrevented) return;
        }

        ev.preventDefault();
        navigate(to);
    };

    return (
        <a class={className} onClick={handleClick} href={to} {...rest}>
            {children}
        </a>
    );
};

export default RouterLink;
