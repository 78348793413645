import SVG from "@client/shared/SVG";
import {
    color,
    contentContainer,
    sizeXS,
    withStyle,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import { Component } from "solid-js";

// const noBgUrls = ["/welcome", "/welcome/"];

interface Props {}

const BackgroundFish: Component<Props> = (props) => {
    // const location = useLocation();

    return (
        // <Show when={!noBgUrls.includes(location.pathname)}>
        <div class={FullscreenContainer}>
            <div class={Container}>
                <SVG
                    className={FishImage}
                    src={`/assets/resources/static/osaka-fish.svg`}
                />
            </div>
        </div>
        // </Show>
    );
};

const FullscreenContainer = css`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: -100;
`;

const Container = css`
    ${contentContainer}
    position: relative;
`;

const FishImage = css`
    position: absolute;
    bottom: 0;
    right: 100%;
    & path {
        fill: ${color("colors/primary-300")} !important;
    }
    width: 400px;
    height: 400px;
    margin-right: 80px;
    ${sizeXS} {
        display: none;
    }
`;

export default withStyle(BackgroundFish, { FullscreenContainer, FishImage });
