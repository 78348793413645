import {css} from "@linaria/core";
import {AppContext} from "@client/context/AppContext";
import {useContext} from "solid-js";
import {isServer} from "solid-js/web";


let LOCK_COUNTER = 0;

export const useLockBodyScroll = isServer
    ? function useLockBodyMock(_locked = () => true, target?: any) {}
    : function useLockBody(locked = () => true) {
        const appCtx = useContext(AppContext);
        let contextScrollLocked = $derefSignal(appCtx.scrollLocked);

        const root = document.getElementById("root")!;
        let wasLocked = false;

        let scrollTop = 0;
        const lock = () => {
            if (wasLocked) return;
            wasLocked = true;

            ++LOCK_COUNTER;
            if (LOCK_COUNTER == 1) {
                scrollTop = window.scrollY || document.documentElement.scrollTop;
                root!.style.top = `-${scrollTop}px`;
                scrollTo(0, 0);
                root!.classList.add(ScrollDisabled);
                contextScrollLocked = true;
            }
        };

        const unlock = () => {
            if (!wasLocked) return;
            wasLocked = false;

            --LOCK_COUNTER;
            if (LOCK_COUNTER == 0) {
                root!.classList.remove(ScrollDisabled);
                root!.style.top = "";
                scrollTo(0, scrollTop);
                contextScrollLocked = false;
            }
        };

        $effect(() => {
            if (locked()) {
                lock();
            } else {
                unlock();
            }
        });

        // clean up, on un-mount
        $effect(() => {
            $cleanup(() => {
                unlock();
            });
        });
    };

const ScrollDisabled = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;