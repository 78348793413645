import Link from "@client/Design/Link/Link";
import Text from "@client/Design/Text";
import DonateCryptoModal from "@client/about/DonateCryptoModal";
import JumbotronHeading from "@client/shared/JumbotronHeading";
import SVG from "@client/shared/SVG";
import Title from "@client/shared/Title";
import {
    bigText,
    breakpointUntil,
    color,
    contentContainer,
    heading1Text,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";

const kofiUrl = "https://ko-fi.com/fujipod";
const paypalUrl = "https://paypal.me/shiro33";
// const localsUrl = "https://fujipod.locals.com/";
const patreonUrl = "https://www.patreon.com/fujiPod";

interface Props {}

const DonateSite: Component<Props> = (props) => {
    let cryptoModalOpen = $signal(false);

    return (
        <>
            <Title>Donate</Title>

            <JumbotronHeading>Support fujiPod</JumbotronHeading>
            <div class={_DonateSite}>
                <Text component="p">
                    We rely on user donations to keep the site running and add
                    new content such as recordings, lessons, quiz questions and
                    more. To keep the content free for everyone without
                    membership fees and paywalls, please consider supporting the
                    development so we can continue doing what we&apos;re doing.
                </Text>

                <div class={ProviderContainer}>
                    <Link
                        className={providerStyle}
                        to={kofiUrl}
                        target="_blank"
                        rel="noreferrer">
                        <Text className={ProviderText}>Ko-fi</Text>
                        <SVG
                            className={ProviderImage}
                            src="/assets/resources/static/donate/kofi-bg.svg"
                        />
                    </Link>
                    <div
                        class={providerStyle}
                        onClick={() => {
                            cryptoModalOpen = true;
                        }}>
                        <Text className={ProviderText}>Crypto</Text>
                        <SVG
                            className={ProviderImage}
                            src="/assets/resources/static/donate/bitcoin-bg.svg"
                        />
                    </div>
                    {/*<Link className={providerStyle} to={localsUrl} target="_blank" rel="noreferrer">*/}
                    {/*    <ProviderText>Locals</ProviderText>*/}
                    {/*    <ProviderImage src="/assets/resources/static/donate/locals-bg.svg"/>*/}
                    {/*</Link>*/}
                    <Link
                        className={providerStyle}
                        to={patreonUrl}
                        target="_blank"
                        rel="noreferrer">
                        <Text className={ProviderText}>Patreon</Text>
                        <SVG
                            className={ProviderImage}
                            src="/assets/resources/static/donate/patreon-bg.svg"
                        />
                    </Link>
                    <Link
                        className={providerStyle}
                        to={paypalUrl}
                        target="_blank"
                        rel="noreferrer">
                        <Text className={ProviderText}>PayPal</Text>
                        <SVG
                            className={ProviderImage}
                            src="/assets/resources/static/donate/paypal-bg.svg"
                        />
                    </Link>
                </div>

                {cryptoModalOpen && (
                    <>
                        <DonateCryptoModal
                            onClose={() => {
                                cryptoModalOpen = false;
                            }}
                        />
                    </>
                )}
            </div>
        </>
    );
};

const _DonateSite = css`
    ${contentContainer}
    margin-bottom: 32px;
`;

const ProviderContainer = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 284px);
    ${breakpointUntil("s")} {
        grid-template-columns: 100%;
    }
    justify-content: center;
    gap: 32px;
    margin: 32px 0 0;

    ${breakpointUntil("s")} {
        flex-direction: column;
        align-items: stretch;
    }
`;

const providerStyle = css`
    position: relative;
    display: block;
    width: 284px;
    height: 158px;
    padding: 16px;
    border-radius: 8px;
    background: ${color("colors/primary-400")};
    cursor: pointer;
    user-select: none;

    ${breakpointUntil("s")} {
        width: 100%;
        height: 180px;
    }
`;

const ProviderText = css`
    ${heading1Text}
    color: ${color("colors/text-600a")};
    ${breakpointUntil("s")} {
        ${bigText}
    }
`;

const ProviderImage = css`
    height: 65%;
    position: absolute;
    right: 16px;
    bottom: 16px;
    fill: ${color("colors/primary-600")};
`;

export default DonateSite;
