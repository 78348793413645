import { Title as TitleBase } from "@solidjs/meta";
import { Component, ComponentProps } from "solid-js";

interface Props extends ComponentProps<typeof TitleBase> {}

const Title: Component<Props> = (props) => {
    const { children, ...rest } = $destructure(props);

    return <TitleBase {...rest}>{children} | fujiPod</TitleBase>;
};

export default Title;
