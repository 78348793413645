import { httpBatchLink } from "@trpc/client";
import { mainConfig } from "@config/main.config";
import { getRequestEvent, isServer } from "solid-js/web";
import superjson from "superjson";
import { omit } from "lodash-es";

const url = () =>
    isServer
        ? `http://localhost:${mainConfig.port}/api/trpc`
        : `${mainConfig.resourceBaseURL}/api/trpc`;
// `http://internal/api/trpc` :

export const trpcConfigStart = () => {
    // event?.request.headers.entries()
    return {
        links: [
            httpBatchLink({
                // node doesn't like this field in particular
                // headers: event ? omit(event..headers, "connection") : undefined,
                get url() {
                    return url();
                },
                // fetch: event?.fetch,
                get fetch(): typeof fetch {
                    return (url, options) => {
                        const event = getRequestEvent();

                        // server
                        if (!event) {
                            return fetch(url, {
                                ...options,
                                headers: {
                                    ...options?.headers,
                                    // authorization: getCookie("accessToken"),
                                },
                            });
                        }

                        // client
                        return fetch(url, {
                            ...options,
                            headers: {
                                ...omit(
                                    [...event.request.headers.entries()].reduce(
                                        (acc, [k, v]) => ({ ...acc, [k]: v }),
                                        {}
                                    ),
                                    "connection"
                                ),
                                ...options?.headers,
                            },
                        });
                    };
                },
            }),
        ],
        transformer: superjson,
    };
};
