/** @jsxRuntime automatic */
/** @jsxImportSource solid-js */
import Heading from "@client/Design/Heading";
import IconText from "@client/Design/IconText.solid";
import Separator from "@client/Design/Separator/Separator";
import Portal from "@client/modal/Portal.solid";
import {
    breakpoint,
    color,
    dropShadow,
    heading2Text,
} from "@client/style/commonStyle";
import { useDocument } from "@core/util/solid/useDocument.solid";
import { useLockBodyScroll } from "@core/util/solid/useLockBodyScroll.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX } from "solid-js";

interface Props {
    children?: JSX.Element;
    title: string;
    fullScreenMobile?: boolean;
    className?: string;
    onClose?: () => void;
    onOutsideClick?: () => void;
}

const Modal: Component<Props> = (props) => {
    const {
        children,
        title,
        className,
        fullScreenMobile,
        onClose,
        onOutsideClick,
    } = $destructure(props);

    const document = useDocument();
    const $prevFocusedElement = document?.activeElement;

    useLockBodyScroll();

    $cleanup(() => {
        if ($prevFocusedElement instanceof HTMLElement) {
            $prevFocusedElement.focus();
        }
    });

    const handleClose = () => {
        window.setTimeout(() => {
            onClose?.();
        }, 200);
    };

    $effect(() => {
        const handleKeyupEvent = (ev: KeyboardEvent) => {
            // 27 = esc
            if (ev.which == 27) {
                handleClose();
                ev.stopPropagation();
            }
        };

        window.addEventListener("keyup", handleKeyupEvent);

        $cleanup(() => {
            window.removeEventListener("keyup", handleKeyupEvent);
        });
    });

    const handleActionButtonClick = () => {
        handleClose?.();
    };
    const handleBackdropClick = () => {
        onOutsideClick?.();
    };

    return (
        <Portal>
            <div class={Backdrop} onClick={handleBackdropClick}>
                <div
                    class={cn(Content, className, { fullScreenMobile })}
                    onClick={(ev) => ev.stopPropagation()}>
                    <div class={Title}>
                        <Heading level={3} className={StyledHeading}>
                            {title}
                        </Heading>
                        <button
                            class={TitleActionButton}
                            data-cy="title-modal-action"
                            onClick={handleActionButtonClick}>
                            {onClose && <IconText icon="cross" />}
                        </button>
                    </div>
                    <Separator margin="8 0" />
                    {children}
                </div>
            </div>
        </Portal>
    );
};

const Backdrop = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    cursor: pointer;

    backdrop-filter: blur(1px);
`;

const Content = css`
    position: relative;
    box-sizing: border-box;
    max-height: 80%;
    max-width: 80%;
    ${dropShadow(0, 4, 8, "rgba(0, 0, 0, 0.15)")}
    background-color: ${color("colors/primary-50")};
    cursor: initial;
    overflow-y: scroll;

    padding: 30px;
    border-radius: 10px;

    ${breakpoint("xs")} {
        max-height: 100%;
        max-width: 100%;

        &.fullScreenMobile {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
`;

const Title = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${color("colors/text-600a")};
    ${heading2Text}
`;

const StyledHeading = css`
    color: ${color("colors/text-600a")} !important;
    ${heading2Text}
`;

const TitleActionButton = css`
    color: inherit;
    align-self: flex-end;
    border: none;
    outline: none;
    background: none;
`;

export default Modal;
