/** @jsxRuntime automatic */
/** @jsxImportSource solid-js */
import { buttonStyle } from "@client/Design/Button/Button.style";
import Link from "@client/Design/Link/Link";
import Button from "@client/solid/design/Button.solid";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps, JSX } from "solid-js";

interface Props extends ComponentProps<typeof Link>, AttributeStyleProps {
    children?: JSX.Element;
    className?: string;
    variant?: ComponentProps<typeof Button>["variant"];
    plain?: boolean;
}

const LinkButton: Component<Props> = (props) => {
    const {
        children,
        variant = "primary",
        className,
        to,
        plain,
        ...other
    } = props;
    const { style, classes, rest } = useAttributeStyle(other);

    const component = plain ? "a" : Link;

    return (
        <Dynamic
            component={component}
            class={cn(variant, componentStyle, className, classes)}
            tabIndex="1"
            style={style}
            {...({ [plain ? "href" : "to"]: to } as any)}
            {...rest}>
            {children}
        </Dynamic>
    );
};

const componentStyle = css`
    @layer c {
        ${buttonStyle}
        &, &:visited, &:hover, &:active {
            text-decoration: none;
        }
    }
`;

export default LinkButton;
