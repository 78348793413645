import LinkButton from "@client/Design/Link/LinkButton.solid";
import GeneralModal from "@client/settings/GeneralModal.solid";
import { Component } from "solid-js";

const buttons =
    ({ onClose }) =>
    () => (
        <>
            <LinkButton to="/welcome" variant="primaryHollow" onClick={onClose}>
                Learn more
            </LinkButton>
            <LinkButton to="/signup" onClick={onClose}>
                Sign up
            </LinkButton>
        </>
    );

interface Props {
    onClose?: () => void;
}

const SignupRequiredModal: Component<Props> = (props) => {
    const { onClose } = $destructure(props);

    return (
        <GeneralModal
            title="Join fujiPod today"
            buttons={buttons({ onClose })}
            onClose={onClose}>
            Many features are available only to registered users.
            <br />
            Sign up for a free account in order to use this feature.
        </GeneralModal>
    );
};

export default SignupRequiredModal;
