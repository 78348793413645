import IconText from "@client/Design/IconText.solid";
import Link from "@client/Design/Link/Link";
import LinkButton from "@client/Design/Link/LinkButton.solid";
import Text from "@client/Design/Text";
import UnorderedList from "@client/Design/UnorderedList.solid";
import UnorderedListItem from "@client/Design/UnorderedListItem.solid";
import GooglePlayLogo from "@client/about/GooglePlayLogo.solid";
import JumbotronHeading from "@client/shared/JumbotronHeading";
import Title from "@client/shared/Title";
import {
    bodyText,
    color,
    contentContainer,
    dropShadow,
    heading1Text,
    primaryFont,
    primaryFontBold,
} from "@client/style/commonStyle";
import { discordInviteUrl } from "@client/welcome/DiscordSection.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

export const androidAppLink =
    "https://play.google.com/store/apps/details?id=com.fujipod.fujipod";

interface Props {}

const NativeAppsSite: Component<Props> = (props) => {
    return (
        <div class={cn(_NativeAppsSite)}>
            <Title>Native Apps</Title>

            <JumbotronHeading wide>Native apps</JumbotronHeading>

            <Text component="p">
                Many features on fujiPod web are limited on mobile devices due
                to system limitations (i.e. playing audio when the screen is
                turned off). The native app provides features that the web
                version cannot.
            </Text>
            <Text component="p">
                The Android app is still in early development, feel free to post
                feedback to our{" "}
                <Link to={discordInviteUrl}>Discord channel</Link>.
            </Text>

            <div class={Card}>
                <h1 class={cn(CardHeading, "relative")}>
                    Android
                    <span class={CardHeadingHype}>beta</span>
                </h1>

                <GooglePlayLogo className={AndroidLogo} />

                <Text margin="16 0 8" font-size="heading3">
                    features
                </Text>

                <UnorderedList>
                    <UnorderedListItem>
                        play study streams with screen off
                    </UnorderedListItem>
                    <UnorderedListItem>native media controls</UnorderedListItem>
                    <UnorderedListItem>
                        reminder notifications
                    </UnorderedListItem>
                    <UnorderedListItem>offline (coming soon)</UnorderedListItem>
                </UnorderedList>

                <LinkButton
                    to={androidAppLink}
                    variant="primary"
                    margin="32 0 0"
                    target="_blank"
                    rel="noopener noreferrer">
                    <IconText icon="box" />
                    Google Play Store
                </LinkButton>
            </div>
        </div>
    );
};

const _NativeAppsSite = css`
    ${contentContainer}
`;

export const Card = css`
    width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    margin: 32px auto 0;
    background: ${color("colors/primary-50")};
    ${dropShadow(0, 2, 4, "rgba(0, 0, 0, 0.15)")}
    text-align: left;
`;

const CardHeading = css`
    ${heading1Text}
    ${primaryFontBold}
`;

const CardHeadingHype = css`
    position: absolute;
    top: -12px;
    right: -32px;
    color: ${color("colors/secondary-800")};
    user-select: none;
    ${bodyText}
    ${primaryFont}
`;

const AndroidLogo = css`
    width: 72px;
    height: 80px;
    margin: 8px 0 0;
`;

export default NativeAppsSite;
