import JumbotronHeading from "@client/shared/JumbotronHeading";
import { heading3Text, primaryFont } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps, JSX } from "solid-js";

interface Props extends ComponentProps<typeof JumbotronHeading> {
    children?: JSX.Element;
}

const JumbotronSubHeading: Component<Props> = (props) => {
    const { className, ...rest } = $destructure(props);
    return (
        <JumbotronHeading className={cn(jumbotronStyle, className)} {...rest} />
    );
};

const jumbotronStyle = css`
    ${JumbotronHeading.styles.HeadingText} {
        ${heading3Text}
        ${primaryFont}
    }
`;

export default JumbotronSubHeading;
