export enum WordSearchJlptFilterSchema {
    All = "All",
    N1 = "N1",
    N2 = "N2",
    N3 = "N3",
    N4 = "N4",
    N5 = "N5"
}

export enum KanjiSearchJlptFilter {
    All = "All",
    N1 = "N1",
    N2 = "N2",
    N3 = "N3",
    N4 = "N4",
    N5 = "N5"
}

export enum WordIndexStudyStatusFilter {
    All = "All",
    Learned = "Learned",
    Learning = "Learning",
}

export enum SentenceBreakdownPosSchema {
    Adjective = "Adjective",
    Adverb = "Adverb",
    Conjunction = "Conjunction",
    Determiner = "Determiner",
    Interjection = "Interjection",
    Noun = "Noun",
    Postposition = "Postposition",
    Prefix = "Prefix",
    Preposition = "Preposition",
    Pronoun = "Pronoun",
    ProperNoun = "ProperNoun",
    Suffix = "Suffix",
    Verb = "Verb"
}

export enum SentenceSearchOrderSchema {
    CreatedAtAsc = "CreatedAtAsc",
    CreatedAtDesc = "CreatedAtDesc",
    StageAsc = "StageAsc",
    StageDesc = "StageDesc"
}


export type Furigana = (string | undefined)[];


export enum QuizType {
    RomajiToHiragana,
    WordInSentenceMeaning,
    WordWritingPractice,
    LearnKanji,
    LearnWord,
    FillTheGap,
    KanjiWritingPractice,
    WordMeaning,
    KanjiMeaning,
}

export enum WordStudyStatus {
    Learning = "LEARNING",
    Learned = "LEARNED",
}

export enum KanjiStudyStatus {
    Learning = "LEARNING",
    Learned = "LEARNED",
}

export enum UserRole {
    Admin = "ADMIN",
    SupporterStudent = "SUPPORTER_STUDENT",
    SupporterSponsor = "SUPPORTER_SPONSOR",
    SupporterEnthusiast = "SUPPORTER_ENTHUSIAST",
    SupporterEmperor = "SUPPORTER_EMPEROR",
    SupporterFamily = "SUPPORTER_FAMILY",
}

export enum ImageType {
    "JPG" = "JPG",
    "GIF" = "GIF",
}

export enum UserSearchFilterSchema {
    All = "All",
    Friends = "Friends"
}

export enum UserSearchOrderSchema {
    Relevance = "Relevance",
    DateRegistered = "DateRegistered",
    Level = "Level"
}

export enum UserActivityType {
    FriendAccept = "FriendAccept",
    WordLearned = "WordLearned",
    WordStartLearning = "WordStartLearning",
    KanjiLearned = "KanjiLearned",
    KanjiStartLearning = "KanjiStartLearning",
    ListenStream = "ListenStream",
}

export enum ReviewType {
    WordMeaning = "WordMeaning",
    KanjiMeaning = "KanjiMeaning",
}

export enum WordPos {
    AdjectiveI = 'ADJECTIVE_I',
    AdjectiveIYoiIi = 'ADJECTIVE_I_YOI_II',
    AdjectiveKari = 'ADJECTIVE_KARI',
    AdjectiveNa = 'ADJECTIVE_NA',
    AdjectiveNaArchaic = 'ADJECTIVE_NA_ARCHAIC',
    AdjectiveNoun = 'ADJECTIVE_NOUN',
    AdjectivePrenoun = 'ADJECTIVE_PRENOUN',
    AdjectiveShiku = 'ADJECTIVE_SHIKU',
    AdjectiveTaru = 'ADJECTIVE_TARU',
    Adverb = 'ADVERB',
    AdverbalNoun = 'ADVERBAL_NOUN',
    AdverbTo = 'ADVERB_TO',
    Auxillary = 'AUXILLARY',
    AuxillaryAdjective = 'AUXILLARY_ADJECTIVE',
    AuxillaryVerb = 'AUXILLARY_VERB',
    Conjunction = 'CONJUNCTION',
    Copula = 'COPULA',
    Counter = 'COUNTER',
    Expression = 'EXPRESSION',
    GodanVerbBu = 'GODAN_VERB_BU',
    GodanVerbGu = 'GODAN_VERB_GU',
    GodanVerbKu = 'GODAN_VERB_KU',
    GodanVerbMu = 'GODAN_VERB_MU',
    GodanVerbNotClassified = 'GODAN_VERB_NOT_CLASSIFIED',
    GodanVerbNu = 'GODAN_VERB_NU',
    GodanVerbRu = 'GODAN_VERB_RU',
    GodanVerbRuIrregular = 'GODAN_VERB_RU_IRREGULAR',
    GodanVerbSpecialAru = 'GODAN_VERB_SPECIAL_ARU',
    GodanVerbSpecialIkuYuku = 'GODAN_VERB_SPECIAL_IKU_YUKU',
    GodanVerbSpecialU = 'GODAN_VERB_SPECIAL_U',
    GodanVerbSu = 'GODAN_VERB_SU',
    GodanVerbTsu = 'GODAN_VERB_TSU',
    GodanVerbU = 'GODAN_VERB_U',
    GodanVerbUru = 'GODAN_VERB_URU',
    IchidanVerb = 'ICHIDAN_VERB',
    IchidanVerbKureru = 'ICHIDAN_VERB_KURERU',
    IchidanVerbZuru = 'ICHIDAN_VERB_ZURU',
    Interjection = 'INTERJECTION',
    IntransitiveVerb = 'INTRANSITIVE_VERB',
    IrregularVerbNu = 'IRREGULAR_VERB_NU',
    IrregularVerbRu = 'IRREGULAR_VERB_RU',
    KuruVerb = 'KURU_VERB',
    NidanLowerVerbBu = 'NIDAN_LOWER_VERB_BU',
    NidanLowerVerbDzu = 'NIDAN_LOWER_VERB_DZU',
    NidanLowerVerbGu = 'NIDAN_LOWER_VERB_GU',
    NidanLowerVerbHuFu = 'NIDAN_LOWER_VERB_HU_FU',
    NidanLowerVerbKu = 'NIDAN_LOWER_VERB_KU',
    NidanLowerVerbMu = 'NIDAN_LOWER_VERB_MU',
    NidanLowerVerbNu = 'NIDAN_LOWER_VERB_NU',
    NidanLowerVerbRu = 'NIDAN_LOWER_VERB_RU',
    NidanLowerVerbSu = 'NIDAN_LOWER_VERB_SU',
    NidanLowerVerbTsu = 'NIDAN_LOWER_VERB_TSU',
    NidanLowerVerbU = 'NIDAN_LOWER_VERB_U',
    NidanLowerVerbYu = 'NIDAN_LOWER_VERB_YU',
    NidanLowerVerbZu = 'NIDAN_LOWER_VERB_ZU',
    NidanUpperVerbBu = 'NIDAN_UPPER_VERB_BU',
    NidanUpperVerbDzu = 'NIDAN_UPPER_VERB_DZU',
    NidanUpperVerbGu = 'NIDAN_UPPER_VERB_GU',
    NidanUpperVerbHuFu = 'NIDAN_UPPER_VERB_HU_FU',
    NidanUpperVerbKu = 'NIDAN_UPPER_VERB_KU',
    NidanUpperVerbMu = 'NIDAN_UPPER_VERB_MU',
    NidanUpperVerbRu = 'NIDAN_UPPER_VERB_RU',
    NidanUpperVerbTsu = 'NIDAN_UPPER_VERB_TSU',
    NidanUpperVerbYu = 'NIDAN_UPPER_VERB_YU',
    NidanVerbU = 'NIDAN_VERB_U',
    Noun = 'NOUN',
    NounOrVerbPrenominal = 'NOUN_OR_VERB_PRENOMINAL',
    NounPrefix = 'NOUN_PREFIX',
    NounProper = 'NOUN_PROPER',
    NounSuffix = 'NOUN_SUFFIX',
    NounTemporal = 'NOUN_TEMPORAL',
    Numeric = 'NUMERIC',
    Particle = 'PARTICLE',
    Prefix = 'PREFIX',
    Pronoun = 'PRONOUN',
    Proverb = 'PROVERB',
    Suffix = 'SUFFIX',
    SuruVerb = 'SURU_VERB',
    SuruVerbIncluded = 'SURU_VERB_INCLUDED',
    SuruVerbSpecial = 'SURU_VERB_SPECIAL',
    SuVerb = 'SU_VERB',
    TransitiveVerb = 'TRANSITIVE_VERB',
    Unclassified = 'UNCLASSIFIED',
    VerbUnspecified = 'VERB_UNSPECIFIED',
    YodanVerbBu = 'YODAN_VERB_BU',
    YodanVerbGu = 'YODAN_VERB_GU',
    YodanVerbHuFu = 'YODAN_VERB_HU_FU',
    YodanVerbKu = 'YODAN_VERB_KU',
    YodanVerbMu = 'YODAN_VERB_MU',
    YodanVerbNu = 'YODAN_VERB_NU',
    YodanVerbRu = 'YODAN_VERB_RU',
    YodanVerbSu = 'YODAN_VERB_SU',
    YodanVerbTsu = 'YODAN_VERB_TSU'
}