import { logger as _logger } from "@core/logging/logger";
import { isServer } from "solid-js/web";

const defaultIdGetter = <R extends string | number | symbol>(obj: { id: R }) =>
    obj.id;

declare global {
    interface Array<T> {
        reduceToIdMap<R extends string | number | symbol>(
            idGetter?: (item: T) => R
        ): Record<R, T>;
    }
}

Array.prototype.reduceToIdMap = function (idGetter = defaultIdGetter) {
    return this.reduce((acc, value) => {
        const id = idGetter(value);
        if (id == null) return;
        return { ...acc, [id]: value };
    }, {});
};

// @ts-ignore
((isServer ? global : window) as any).logger = _logger;

// if (IS_DEV) {
//     const _log = console.log;
//     console.log = (...args: any[]) => {
//         // ignore mini-css-plugin's HMR errors
//         if (args[0] == "no window.document found, will not HMR CSS") return;
//
//         _log(...args);
//     }
// }

export default {};

