import SVG from "@client/shared/SVG";
import {
    breakpoint,
    breakpointFrom,
    breakpointUntil,
    color,
    contentContainer,
    heading1Text,
    heading3Text,
    primaryFontBold,
    withStyle,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX, Show } from "solid-js";

interface Props {
    children?: JSX.Element;
    sub?: string;
    className?: string;
    // breaks out of content containers
    wide?: boolean;
    mobileOnly?: boolean;
}

const JumbotronHeading: Component<Props> = (props) => {
    const { children, className, sub, mobileOnly, wide, ...rest } =
        $destructure(props);

    return (
        <div
            class={cn(_JumbotronHeading, className, { wide, mobileOnly })}
            {...rest}>
            <SVG
                className={BG}
                width="100%"
                height="100%"
                src={`/assets/resources/static/wave-pattern.svg`}
            />
            <div class={Cover} />
            <span class={cn(HeadingText, { compact: !!sub })}>{children}</span>
            <Show when={sub}>
                <span class={SubText}>{sub}</span>
            </Show>
        </div>
    );
};

const _JumbotronHeading = css`
    @layer c {
        & {
            ${contentContainer}
            position: relative;
            margin-bottom: 32px;
            background: ${color("colors/primary-100")};
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            overflow: hidden;

            ${breakpoint("xs", "s")} {
                width: 100%;
            }

            &.wide {
                ${breakpoint("m")} {
                    margin-left: -32px;
                    width: calc(100% + 64px);
                }
                ${breakpointUntil("m")} {
                    margin-left: -16px;
                    width: calc(100% + 32px);
                }
            }
            &.mobileOnly {
                ${breakpointFrom("s")} {
                    display: none;
                }
            }
        }
    }
`;

const BG = css`
    fill: ${color("colors/primary-300")} !important;
    position: absolute;
    top: 0;
    left: 0;
`;

const Cover = css`
    width: 100%;
    height: 100%;
    background: ${color("colors/primary-100")};
    position: absolute;
    top: 0;
    left: 0;
    mask-image: -webkit-gradient(
        linear,
        left center,
        right center,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0.2))
    );
`;

const HeadingText = css`
    width: 100%;
    position: relative;
    z-index: 20;
    padding: 16px 32px;
    ${heading1Text}
    ${primaryFontBold}
  &.compact {
        padding-bottom: 8px;
    }
`;

const SubText = css`
    position: relative;
    width: 100%;
    padding: 0 32px 16px;
    ${heading3Text}
`;

export default withStyle(JumbotronHeading, {
    Container: _JumbotronHeading,
    HeadingText,
});
