import { getEnv } from "@server/util/getEnv";

const httpsEnabled = () =>
    getEnv("HTTPS_ENABLED") ?? import.meta.env.HTTPS_ENABLED;
const publicProto = () => (httpsEnabled() ? "https" : "http");

const publicHost = () => getEnv("PUBLIC_HOST") ?? import.meta.env.PUBLIC_HOST;

const publicPort = () => getEnv("PUBLIC_PORT") ?? import.meta.env.PUBLIC_PORT;

export const mainConfig = {
    // get port() {return IS_DEV ? process.env["CONTAINER_DEV_PORT"] : process.env["CONTAINER_PORT"]},
    get port() {
        return getEnv("CONTAINER_PORT") ?? import.meta.env.CONTAINER_PORT;
    },
    get publicHost() {
        return publicHost();
    },
    get publicPort() {
        return publicPort();
    },
    get httpsEnabled() {
        return httpsEnabled();
    },
    get publicProto() {
        return publicProto();
    },
    get serviceWorkerEnabled() {
        return (
            getEnv("SERVICE_WORKER_ENABLED") ??
            import.meta.env.SERVICE_WORKER_ENABLED
        );
    },
    get resourceBaseURL() {
        return `${publicProto()}://${publicHost()}:${publicPort()}`;
    },
    searchPage: {
        resultsPerPage: 16,
    },
    recordingsManager: {
        resultsPerPage: 20,
    },
    wordIndexSite: { resultsPerPage: 10 },
    kanjiIndexSite: { resultsPerPage: 10 },
};
