import { IS_NATIVE } from "@client/native/platform";
import { capacitorConfigPlugin } from "@client/native/capacitorConfigPlugin";
import { merge } from "lodash-es";

console.log("<<<<", IS_NATIVE);

if (IS_NATIVE) {
    const nativeConfig = await capacitorConfigPlugin!.get();
    console.log(nativeConfig);

    merge(window, {
        // __SSR_ENABLED__: false,
        process: {
            env: {
                ...nativeConfig,
                // SERVICE_WORKER_ENABLED: false,
            },
        },
    });
} else {
    // merge(window, (window as any).__SSR_VARIABLES__, window.__SSR_ENABLED__);
    // @ts-ignore
    const SSREnv = window.__SSR_ENV;
    if (SSREnv) {
        merge(window, { process: { env: SSREnv } });
    }
}
