import { SSRContext } from "@client/context/SSRContext";
import { clientConfig } from "@config/client.config";
import { mainConfig } from "@config/main.config";
import { Component, useContext } from "solid-js";
import { NoHydration, isServer } from "solid-js/web";

interface Props {}

const SerializeSSRValuesScript: Component<Props> = (props) => {
    const ssrCtx = useContext({ ...SSRContext });

    if (!isServer) return null;

    return (
        <NoHydration>
            <script
                innerHTML={`window.__SSR_CTX = ${JSON.stringify(ssrCtx)}`}
            />
            <script
                innerHTML={`window.__SSR_ENV = ${JSON.stringify({
                    HTTPS_ENABLED: mainConfig.httpsEnabled,
                    PUBLIC_HOST: mainConfig.publicHost,
                    PUBLIC_PORT: mainConfig.publicPort,
                    SERVICE_WORKER_ENABLED: mainConfig.serviceWorkerEnabled,
                    LOG_LEVEL: clientConfig.logLevel,
                })}`}
            />
        </NoHydration>
    );
};

export default SerializeSSRValuesScript;
