import { color, style } from "@client/style/commonStyle";
import { css } from "@linaria/core";

export const LinkStyle = style`
  &, &:visited, &:hover, &:active {
    color: ${color("colors/primary-800")};
  }
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

export const LinkInput = css`
    @layer c {
        ${LinkStyle}
    }
`;
