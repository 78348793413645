import LinkButton from "@client/Design/Link/LinkButton.solid";
import {
    ErrorBanner,
    ErrorBannerSub,
    ErrorReason,
    HomeButton,
    TextContainer,
} from "@client/errorPages/ErrorPages.style";
import Title from "@client/shared/Title";

interface Props {}

const NotFound: Component<Props> = (props) => {
    // const {staticContext} = props;
    // if (isDefined(staticContext)) staticContext.statusCode = 404;

    return (
        <>
            <Title>404 page not found</Title>

            <div class={TextContainer}>
                <h1 class={ErrorBanner}>404 Error</h1>
                <h2 class={ErrorBannerSub}>Page not found</h2>
                <div class={ErrorReason}>
                    Sorry, the content you’re looking for is on the other side
                    of Fuji.
                </div>
                <LinkButton className={HomeButton} to="/">
                    Back to dashboard
                </LinkButton>
            </div>
        </>
    );
};

export default NotFound;
