import Box from "@client/Design/box/Box";
import Modal from "@client/modal/Modal.solid";
import { css } from "@linaria/core";
import { Component, JSX } from "solid-js";

type ButtonRenderFn = () => JSX.Element;

interface Props {
    children?: JSX.Element;
    buttons: ButtonRenderFn;
    title: string;
    onClose?(): void;
}

const GeneralModal: Component<Props> = (props) => {
    const { children, title, buttons, onClose } = $destructure(props);

    return (
        <Modal
            className={StyledModal}
            title={title}
            onOutsideClick={onClose}
            onClose={onClose}>
            <Box margin="0 0 32">{children}</Box>

            <Box display="flex" gap="8px" justify-content="flex-end">
                {buttons()}
            </Box>
        </Modal>
    );
};

const StyledModal = css`
    min-width: 500px;
`;

export default GeneralModal;
