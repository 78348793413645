import "@client/native/nativeEnv";
import "@core/util/extensions/generalExtensions";

import ClientApp from "@client/app/ClientApp";
import { mount } from "@solidjs/start/client";
import { mainConfig } from "@config/main.config";

logger.info("UI", "started app", {
    resourceBaseUrl: mainConfig.resourceBaseURL,
});

mount(() => {
    return ClientApp();
}, document.getElementById("root")!);

// await (async () => {
//     console.log("init 2")
//     console.log("init 3");
// })();

// const ws = new WebSocket("ws://localhost:3000/_ws");
