import {
    bigText,
    bodyText,
    color,
    FontSize,
    FontStyle,
    heading1Text,
    heading2Text,
    heading3Text,
    jumboText,
    largeText,
    primaryFont,
    primaryFontBold,
    smallText,
    subText
} from "@client/style/commonStyle";
import {css} from "@linaria/core";
import {createMemo, JSX} from "solid-js";

const marginCls1 = css`margin: var(--margin1);`;
const marginCls2 = css`margin: var(--margin1) var(--margin2);`;
const marginCls3 = css`margin: var(--margin1) var(--margin2) var(--margin3);`;
const marginCls4 = css`margin: var(--margin1) var(--margin2) var(--margin3) var(--margin4);`;
const paddingCls1 = css`padding: var(--padding1);`;
const paddingCls2 = css`padding: var(--padding1) var(--padding2);`;
const paddingCls3 = css`padding: var(--padding1) var(--padding2) var(--padding3);`;
const paddingCls4 = css`padding: var(--padding1) var(--padding2) var(--padding3) var(--padding4);`;
const gridGapCls = css`grid-gap: var(--grid-gap1);`;
const gapCls = css`gap: var(--gap1);`;
const widthCls = css`width: var(--width1);`;
const maxWidthCls = css`max-width: var(--max-width1);`;
const heightCls = css`height: var(--height1);`;
const overflowCls = css`overflow: var(--overflow1);`;

const primaryFontStyleCls = css`${primaryFont}`;
const primaryFontBoldStyleCls = css`${primaryFontBold}`;

const subTextFontSizeCls = css`${subText}`;
const smallTextFontSizeCls = css`${smallText}`;
const bodyTextFontSizeCls = css`${bodyText}`;
const heading3TextFontSizeCls = css`${heading3Text}`;
const heading2TextFontSizeCls = css`${heading2Text}`;
const heading1TextFontSizeCls = css`${heading1Text}`;
const bigTextFontSizeCls = css`${bigText}`;
const largeTextFontSizeCls = css`${largeText}`;
const jumboTextFontSizeCls = css`${jumboText}`;

const supportedAttrs = {
    width: widthCls,
    "max-width": maxWidthCls,
    height: heightCls,
    overflow: overflowCls,
    margin: [marginCls1, marginCls2, marginCls3, marginCls4],
    padding: [paddingCls1, paddingCls2, paddingCls3, paddingCls4],
    "grid-gap": gridGapCls,
    gap: gapCls,
    "font-style": {primary: primaryFontStyleCls, primaryBold: primaryFontBoldStyleCls},
    "font-size": {
        "sub": subTextFontSizeCls,
        "small": smallTextFontSizeCls,
        "body": bodyTextFontSizeCls,
        "heading3": heading3TextFontSizeCls,
        "heading2": heading2TextFontSizeCls,
        "heading1": heading1TextFontSizeCls,
        "big": bigTextFontSizeCls,
        "large": largeTextFontSizeCls,
        "jumbo": jumboTextFontSizeCls,
    },
};

const supportedAttrsDirect = ["display", "color", "background", "fill", "flex-direction", "flex-wrap", "align-items", "align-self", "justify-content", "justify-self", "grid-area", "position", "text-align", "cursor", "flex", "overflow-x", "user-select"];

// const keyMapper = { };

const valueMapper = {
    color: (value: string) => color(value),
    background: (value: string) => color(value),
    fill: (value: string) => color(value),
};

export const useAttributeStyle = <T extends Record<string, any>>(styleAttr: T) => {
    return createMemo(() => _useAttributeStyle(styleAttr))();
}

export const _useAttributeStyle = <T extends Record<string, any>>(styleAttr: T) => {
    // const restKeys: string[] = [];
    const rest: any = {};
    const classes: string[] = [];
    const style: Record<string, any> = styleAttr.style || {};

    for (let [key, value] of Object.entries(styleAttr)) {
        if (key == "style") continue;
        if (typeof value != "string") {
            rest[key] = value;
            // restKeys.push(key);
            continue;
        }
        if (valueMapper[key]) value = valueMapper[key](value);

        // directly apply some styles such as color
        if (supportedAttrsDirect.includes(key)) {
            // if (keyMapper[key]) key = keyMapper[key];

            style[key] = value;
            continue;
        }

        if (!supportedAttrs[key]) {
            rest[key] = value;
            continue;
        }

        const values = value.split(" ");

        // handle directional attributes (i.e. margin's 4 sides)
        if (Array.isArray(supportedAttrs[key])) {
            classes.push(supportedAttrs[key][values.length - 1]);
        } else if (typeof supportedAttrs[key] == "object") {
            classes.push(supportedAttrs[key][value]);
            continue;
        } else {
            classes.push(supportedAttrs[key]);
        }

        for (const [i, value] of values.entries()) {
            if (!isNaN(+value)) {
                style[`--${key}${i + 1}`] = (+value / 16) + "rem";
            } else {
                style[`--${key}${i + 1}`] = value;
            }
        }

    }

    // const [rest ] = splitProps(styleAttr, restKeys);

    return {classes, style, rest: rest as Omit<T, "style">};
};

export interface AttributeStyleProps {
    width?: string;
    "max-width"?: string;
    height?: string;
    cursor?: "initial" | "pointer";
    fill?: string;
    margin?: string;
    padding?: string;
    "grid-gap"?: string;
    gap?: string;
    "align-items"?: "flex-start" | "flex-end" | "center" | "baseline";
    "align-self"?: "flex-start" | "flex-end" | "center" | "baseline";
    "justify-content"?: "flex-start" | "flex-end" | "center" | "baseline";
    "justify-elf"?: "flex-start" | "flex-end" | "center";
    "text-align"?: "left" | "center" | "right" | "baseline";
    color?: string;
    background?: string;
    "font-style"?: FontStyle;
    "font-size"?: FontSize;
    display?: "block" | "inline-block" | "flex" | "inline-flex";
    flex?: string;
    overflow?: "hidden" | "visible";
    "overflow-x"?: "scroll";
    "flex-direction"?: "row" | "column";
    "flex-wrap"?: "wrap" | "initial";
    "grid-area"?: string;
    "user-select"?: "initial" | "none";
    position?: "absolute" | "relative" | "fixed";
    style?: JSX.CSSProperties | string;
}