import BackgroundFish from "@client/BackgroundFish";
import Link from "@client/Design/Link/Link";
import Text from "@client/Design/Text";
import GooglePlayLogo from "@client/about/GooglePlayLogo.solid";
import { androidAppLink } from "@client/about/NativeAppsSite.solid";
import { useAppCtx } from "@client/context/AppContext";
import { IS_NATIVE } from "@client/native/platform";
import {
    seekbarHeight,
    seekbarHeightM,
    seekbarHeightS,
} from "@client/seekbar/Seekbar";
import SVG from "@client/shared/SVG";
import {
    boxShadow,
    breakpointUntil,
    color,
    contentContainer,
    smallText,
    subText,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props {}

const Footer: Component<Props> = (props) => {
    const appCtx = useAppCtx();
    const seekbarVisible = $derefSignal(appCtx.seekbarVisible);

    return (
        <div class={cn(_Footer, { seekbarVisible })}>
            <div class={cn(Background, { native: IS_NATIVE })}>
                <BackgroundFish />
                <div class={cn(Content, { seekbarVisible })}>
                    <div class={Column}>
                        <Text className={ColumnHeading}>Links</Text>
                        <Link
                            className={SitemapLink}
                            to={androidAppLink}
                            target="_blank"
                            rel="noopener noreferrer">
                            <GooglePlayLogo className={ExternalLogo} />
                            <Text>Google Play Store</Text>
                        </Link>
                        <Link
                            className={SitemapLink}
                            to="https://twitter.com/fuji_pod"
                            target="_blank"
                            rel="noopener noreferrer">
                            <SVG
                                src="/assets/resources/static/twitter-logo.svg"
                                class={ExternalLogo}
                            />
                            <Text>Twitter</Text>
                        </Link>
                    </div>
                    <div class={Column}>
                        <Text className={ColumnHeading}>Dictionary</Text>
                        <Link className={SitemapLink} to="/dictionary/words">
                            Words
                        </Link>
                        <Link className={SitemapLink} to="/dictionary/kanji">
                            Kanji
                        </Link>
                        <Link
                            className={SitemapLink}
                            to="/dictionary/sentences">
                            Sentences
                        </Link>
                        <Link className={SitemapLink} to="/dictionary/lessons">
                            Lessons
                        </Link>
                    </div>
                    <div class={Column}>
                        <Text className={ColumnHeading}>User</Text>
                        <Link className={SitemapLink} to="/">
                            Dashboard
                        </Link>
                        <Link className={SitemapLink} to="/community">
                            Community
                        </Link>
                        <Link className={SitemapLink} to="/study">
                            Study
                        </Link>
                        <Link className={SitemapLink} to="/privacy-policy">
                            Privacy policy
                        </Link>
                    </div>
                    <div class={Column}>
                        <Text className={ColumnHeading}>Support</Text>
                        <Link className={SitemapLink} to="/about">
                            About
                        </Link>
                        <Link className={SitemapLink} to="/about/faq">
                            FAQ
                        </Link>
                        <Link className={SitemapLink} to="/">
                            Contact us
                        </Link>
                        <Link className={SitemapLink} to="/about/donate">
                            Support us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const _Footer = css`
    margin: auto 0 0;
`;

const Background = css`
    padding: 16px 0;
    background: ${color("colors/primary-50")};
    ${boxShadow(0, 0, 7, "rgba(0, 0, 0, 0.1)", { inset: true })}
    clip-path: inset(0 0 0 0);
    ${breakpointUntil("m")} {
        display: none;
    }
    &.native {
        display: none;
    }
    ${BackgroundFish.styles.FullscreenContainer} {
        display: none;
        z-index: 100;

        @supports (clip-path: inset(0 0 0 0)) {
            display: initial;
        }
    }

    ${BackgroundFish.styles.FishImage} {
        & path {
            fill: ${color("background-fish-footer")} !important;
        }
    }
`;

const Content = css`
    ${contentContainer}
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;

    &.seekbarVisible {
        padding-bottom: ${seekbarHeight}px;
        ${breakpointUntil("m")} {
            height: ${seekbarHeightM}px;
        }
        ${breakpointUntil("s")} {
            height: ${seekbarHeightS}px;
        }
    }
`;

const Column = css`
    display: flex;
    flex-direction: column;
    ${smallText}
`;

const ColumnHeading = css`
    color: ${color("colors/text-300a")};
    margin: 0 0 4px;
    ${subText}
`;

const SitemapLink = css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
`;

const ExternalLogo = css`
    height: 16px;
    width: 16px;
    fill: ${color("colors/primary-450")};
`;

// export const FooterSolid = solidify(Footer, {
//     contexts: [AppContext, __RouterContext],
//     wrapperClassName: css`margin-top: auto;`,
// });
export default Footer;
