import {QueryClient} from "@tanstack/solid-query";


export const makeQueryClient = () => new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
            throwOnError: true,

            staleTime: Infinity,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});