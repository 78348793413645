import { portalContainerId } from "@client/modal/PortalContainer";
import { Component, JSX } from "solid-js";
import { Portal as SolidPortal, isServer } from "solid-js/web";

interface Props {
    children?: JSX.Element;
}

const Portal: Component<Props> = (props) => {
    const { children } = $destructure(props);

    if (isServer) return null;

    const $container = document.getElementById(
        portalContainerId
    ) as HTMLDivElement;

    return <SolidPortal mount={$container}>{children}</SolidPortal>;
};

export default Portal;
