import { boxShadow, color, style } from "@client/style/commonStyle";
import { css } from "@linaria/core";

export const primaryInputStyle = style`
  padding: 5px 20px 7px;
  background: ${color("colors/primary-300")};
  border: none;
  border-radius: 4px;
  color: ${color("colors/text-600a")};
  ${boxShadow(0, 2, 3, "rgba(0, 0, 0, 0.05)")}
`;

export const underlineInputStyle = style`
  border: none;
  background: transparent !important;
  color: ${color("colors/text-600a")};
  border-bottom: 4px solid ${color("colors/text-300a")} !important;
`;

export const InputInput = css`
    @layer c {
        font-size: inherit;
        line-height: inherit;
        outline: none;

        &::placeholder {
            opacity: 1;
        }

        &::placeholder {
            color: ${color("colors/text-100a")};
        }

        &.primary {
            ${primaryInputStyle}
            &:active, &:focus {
                background: ${color("colors/primary-200")};
                ${boxShadow(0, 2, 3, "rgba(0, 0, 0, 0.1)")}
            }

            &:disabled {
                color: ${color("colors/text-100a")};
                background: ${color("colors/primary-100")};
            }
        }

        &.underline {
            background: transparent;
            ${underlineInputStyle}
        }
    }
`;
