import { lazy } from "solid-lazy-plus";
import { dictionarySite } from "@client/dictionary/DictionarySite";
import { communitySite } from "@client/community/CommunitySite";
import { aboutSite } from "@client/about/AboutSite";
import { studySite } from "@client/study/StudySite";
import SearchbarWithContent from "@client/searchBar/SearchbarWithContent";

const PrivacyPolicySite = lazy(() => import("@client/PrivacyPolicySite"));

export const routes = [
    dictionarySite,
    communitySite,
    studySite,
    aboutSite,
    {
        path: "/welcome",
        component: lazy(() => import("@client/welcome/WelcomeSite")),
    },
    {
        path: "/privacy-policy",
        component: () => (
            <SearchbarWithContent wide>
                <PrivacyPolicySite />
            </SearchbarWithContent>
        ),
    },
    // {
    //     path: "/test",
    //     component: lazy(() => import("@client/Test")),
    // },
    {
        path: "/",
        component: lazy(() => import("@client/dashboard/DashboardSite")),
    },
];
