import { boxShadow, color, dropShadow, style } from "@client/style/commonStyle";

export const buttonShadow = style`
  ${boxShadow(0, -2, 0, "rgba(0, 0, 0, 0.25)", { inset: true })}
  ${dropShadow(0, 2, 3, "rgba(0, 0, 0, 0.05)")}
`;

export const neutralButtonStyle = style`
  color: ${color("colors/text-600a")};
  background: ${color("colors/primary-300")};
  ${buttonShadow}

  &:hover, &:focus {
    color: ${color("colors/text-600a")};
    background: ${color("colors/primary-400")};
  }
  &:active {
    color: ${color("colors/text-600b")};
    background: ${color("colors/primary-450")};
  }
  &:disabled {
    color: ${color("colors/text-100a")};
    background: ${color("colors/primary-300")};
  }
`;

export const primaryButtonStyle = style`
  background: ${color("colors/primary-500")};
  ${buttonShadow}

  &:hover, &:focus {
    color: ${color("colors/text-900b")};
    background: ${color("colors/primary-600")};
  }
  &:active {
    color: ${color("colors/text-900b")};
    background: ${color("colors/primary-900")};
  }
  &:disabled {
    color: ${color("colors/text-100a")};
    background: ${color("colors/primary-400")};
  }
`;

export const secondaryButtonStyle = style`
  background: ${color("colors/secondary-500")};
  ${buttonShadow}

  &:hover, &:focus {
    color: ${color("colors/text-900b")};
    background: ${color("colors/secondary-600")};
  }
  &:active {
    color: ${color("colors/text-900b")};
    background: ${color("colors/secondary-900")};
  }
  &:disabled {
    color: ${color("colors/text-100a")};
    background: ${color("colors/secondary-400")};
  }
`;

export const primaryHollowButtonStyle = style`
  background: none;
  border: 2px solid ${color("button/hollow-primary")};
  padding-top: 0;
  padding-bottom: 1px;
  box-shadow: none;
  &, &:visited {
    color: ${color("button/hollow-primary")};
  }
  &:hover, &:focus {
    color: ${color("button/hollow-primary-text-hover")};
    background: ${color("button/hollow-primary")};
  }
  &:active {
    color: ${color("button/hollow-primary-text-active")};
    background: ${color("button/hollow-primary-active")} !important;
  }
  &:disabled {
    color: ${color("button/hollow-primary-text-disabled")};
    border-color: ${color("button/primary-disabled")};
  }
`;

export const secondaryHollowButtonStyle = style`
  background: none;
  border: 2px solid ${color("button/hollow-secondary")};
  padding-top: 0;
  padding-bottom: 1px;
  box-shadow: none;
  &, &:visited {
    color: ${color("button/hollow-secondary")};
  }
  &:hover {
    color: ${color("button/hollow-secondary-text-active")};
    background: ${color("button/hollow-secondary")};
  }
  &:active {
    color: ${color("button/hollow-secondary-text-active")};
    background: ${color("button/hollow-secondary-active")} !important;
  }
  &:disabled {
    color: ${color("button/hollow-secondary-text-disabled")};
    border-color: ${color("button/secondary-disabled")};
  }
`;

export const buttonStyle = style`
  display: flex;
  justify-content: center;
  gap: 4px;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  padding: 1px 8px 3px;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  &, &:visited {
    color: ${color("colors/text-600b")};
  }

  &.neutral { ${neutralButtonStyle} }
  &.primary { ${primaryButtonStyle} }
  &.secondary { ${secondaryButtonStyle} }
  &.primaryHollow { ${primaryHollowButtonStyle} }
  &.secondaryHollow { ${secondaryHollowButtonStyle} }
`;

// export const ButtonInput = styled.button`
//   ${buttonStyle}
// `;
