import { useLocation, useNavigate } from "@solidjs/router";
import { omit } from "lodash-es";
import { Signal, createMemo } from "solid-js";

type Location = ReturnType<typeof useLocation>;
const getUrlParams = (location: Location) =>
    Object.fromEntries(new URLSearchParams(location.search).entries());

let currentFrameParams = $signal<any>();

export function useQueryParam(
    name: string,
    type?: "string"
): Signal<string | undefined>;
export function useQueryParam(
    name: string,
    type?: "number"
): Signal<number | undefined>;
export function useQueryParam(
    name: string,
    type?: "boolean"
): Signal<boolean | undefined>;
export function useQueryParam(
    name: string,
    type: "string" | "number" | "boolean" = "string"
): Signal<any> {
    const location = useLocation();
    const navigate = useNavigate();

    let params = $signal(getUrlParams(location));

    $effect(() => {
        params = getUrlParams(location);
        currentFrameParams = undefined;
    });

    const value = createMemo(() => {
        const param = params[name];
        if (param === undefined) return undefined;
        if (type == "number") return parseInt(param);
        if (type == "boolean") return !!param;
        return param;
    });

    const setValue = (newValue) => {
        if (currentFrameParams) {
            params = currentFrameParams;
        }

        if (typeof newValue == "function") newValue = newValue(value());

        if (newValue != undefined) {
            switch (type) {
                case "string":
                    if (typeof newValue != "string")
                        throw new Error(
                            `expected type 'string', got '${typeof newValue}'`
                        );
                    break;
                case "number":
                    if (typeof newValue != "number")
                        throw new Error(
                            `expected type 'number', got '${typeof newValue}'`
                        );
                    break;
                case "boolean":
                    if (typeof newValue != "boolean")
                        throw new Error(
                            `expected type 'boolean', got '${typeof newValue}'`
                        );
                    break;
            }
        }

        const newParams =
            newValue == undefined
                ? omit(params, name)
                : {
                      ...omit(params, name),
                      [name]: encodeURIComponent(newValue),
                  };

        let search = Object.entries(newParams)
            .map(([k, v]) => `${k}=${v}`)
            .join("&");

        if (search != "") search = "?" + search;
        navigate(`${location.pathname}${search}`);

        currentFrameParams = newParams;
    };

    return [value, setValue] as any;
}
