import {Accessor, createSignal, Setter, Signal, untrack} from "solid-js";


export const createLazySignal = <T extends any>(mem: Accessor<T>): Signal<T> => {
    let dirty = false;
    const [storedValue, setStoredValue] = createSignal<T | null>(null);

    const getter: Accessor<T> = () => {
        const _s = storedValue();
        // console.log("GET", dirty, dirty ? _s : mem());
        if (!dirty) return mem();
        return _s as T;
    };

    const setter = (value) => {
        if (typeof value == "function") value = value(dirty ? untrack(storedValue) : untrack(mem));
        dirty = true;
        setStoredValue(value);
    };

    return [getter, setter as Setter<T>];
};