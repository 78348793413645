import Separator from "@client/Design/Separator/Separator";
import {
    breakpoint,
    breakpointFrom,
    color,
    dropShadow,
    withStyle,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX } from "solid-js";

interface Props {
    ref?: HTMLDivElement | ((ref: HTMLDivElement) => void);
    children?: JSX.Element;
    className?: string;
}

const HeaderModal: Component<Props> = (props) => {
    const { children, className } = $destructure(props);

    return (
        <div class={cn(_HeaderModal, className)}>
            <div class={BoxArrow} />

            <div class={BoxContainer} ref={props.ref}>
                {children}
            </div>
        </div>
    );
};

const _HeaderModal = css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 500;
    ${dropShadow(0, 0, 10, "rgba(0, 0, 0, 0.2)")}

    ${Separator.styles.Line} {
        border-color: ${color("dropdown/separator")};
    }
`;

const BoxArrow = css`
    width: 0;
    height: 0;
    align-self: flex-end;
    z-index: 502;
    margin-right: 16px;
    margin-top: 5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid ${color("colors/primary-50")};
`;

const BoxContainer = css`
    background: ${color("colors/primary-50")};
    z-index: 501;
    padding: 16px;
    border-radius: 4px;
    margin-right: -10px;
    ${breakpoint("xs")} {
        margin-right: 0;
    }
    ${breakpointFrom("l")} {
        margin-right: -20px;
    }
`;

export default withStyle(HeaderModal, { Container: BoxContainer });
