import Separator from "@client/Design/Separator/Separator";
import { boxShadow, color, text } from "@client/style/commonStyle";
import { bodyTextHeight } from "@client/style/textStylesTS";
import { trpc } from "@client/trpc/trpcClientUtil";
import radicalList from "@core/generated/radicalList.json";
import { css } from "@linaria/core";
import cn from "classnames";
import { without } from "lodash-es";
import { For } from "solid-js";

interface Props {
    ref?: (r: HTMLDivElement) => void;
    onKanjiSelect?: (kanji: string) => void;
}

const RadicalSearchPopup: Component<Props> = (props) => {
    const { ref, onKanjiSelect } = $destructure(props);

    let selectedRadicals = $signal<string[]>([]);
    let selectableRadicals = $signal<string[]>([]);
    let sortedKanjiMatches = $signal<Record<number, any>>({});

    const query = trpc.shared.radicalSearch.createQuery(() => ({
        radicals: selectedRadicals,
    }));

    $effect(() => {
        if (!query.data) return;
        const kanjiMatches = query.data.kanji;
        selectableRadicals = query.data.radicals;

        const _sortedKanjiMatches: Record<string, any> = {};
        for (const kanji of kanjiMatches) {
            if (!_sortedKanjiMatches[kanji.strokeCount])
                _sortedKanjiMatches[kanji.strokeCount] = [];
            _sortedKanjiMatches[kanji.strokeCount].push(kanji);
        }
        sortedKanjiMatches = _sortedKanjiMatches;
    });

    const handleKanjiSelect = (character: string) => {
        onKanjiSelect?.(character);
        selectedRadicals = [];
    };

    return (
        <div ref={ref} class={cn(Container)}>
            <div
                class={cn(RadicalListing, KanjiListing)}
                data-cy="radical-search-kanji-list">
                <For each={Object.entries(sortedKanjiMatches)}>
                    {([strokeCount, kanjiList]) => (
                        <>
                            <div class={cn(Element, "inactive")}>
                                {strokeCount}
                            </div>

                            <For each={kanjiList}>
                                {(kanji) => (
                                    <div
                                        class={Element}
                                        onClick={() =>
                                            handleKanjiSelect(kanji.id)
                                        }>
                                        {kanji.id}
                                    </div>
                                )}
                            </For>
                        </>
                    )}
                </For>
            </div>
            <Separator />
            <div class={RadicalListing} data-cy="radical-search-radical-list">
                <For each={Object.entries(radicalList)}>
                    {([strokeCount, radicals]: [string, string[]]) => (
                        <>
                            <div class={cn(Element, "inactive")}>
                                {strokeCount}
                            </div>

                            <For each={radicals}>
                                {(radical) => {
                                    const selected = $memo(
                                        selectedRadicals.includes(radical)
                                    );
                                    const inactive = $memo(
                                        !selected &&
                                            selectedRadicals.length &&
                                            !selectableRadicals.includes(
                                                radical
                                            )
                                    );

                                    const handleClick = () => {
                                        if (selected) {
                                            selectedRadicals = without(
                                                selectedRadicals,
                                                radical
                                            );
                                        } else if (!inactive) {
                                            selectedRadicals = [
                                                ...selectedRadicals,
                                                radical,
                                            ];
                                        }
                                    };

                                    return (
                                        <div
                                            class={cn(Element, {
                                                inactive,
                                                selected,
                                            })}
                                            onClick={handleClick}>
                                            {radical}
                                        </div>
                                    );
                                }}
                            </For>
                        </>
                    )}
                </For>
            </div>
        </div>
    );
};

const Container = css`
    position: absolute;
    z-index: 50;
    top: 100%;
    width: 100%;
    background: ${color("colors/primary-50")};
    ${boxShadow(0, 2, 6, "rgba(0, 0, 0, 0.2)")}
    padding: 0 16px 16px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const RadicalListing = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 16px);
    grid-column-gap: 4px;
    grid-auto-rows: ${bodyTextHeight}px;
`;

const KanjiListing = css`
    overflow: hidden;
    height: ${2 * bodyTextHeight}px;
`;

const Element = css`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    user-select: none;
    ${text("primary", "sub", "colors/text-600a")}
    &.inactive {
        color: ${color("colors/text-100a")};
        cursor: initial;
    }
    &.selected {
        color: ${color("link-primary-600")};
    }
`;

export default RadicalSearchPopup;
