import { debounce } from "lodash-es";
import { isServer } from "solid-js/web";

interface Options {
    debounceTimeout?: number;
}

export const useWindowSize = (options: Options = {}) => {
    if (isServer) {
        return {
            windowSize: () => [0, 0],
            unsubscribe: () => {},
        };
    }

    let size = $signal<[number, number]>([
        window.innerWidth,
        window.innerHeight,
    ]);
    const cb = () => {
        size = [window.innerWidth, window.innerHeight];
    };
    const _cb = debounce(cb, options.debounceTimeout ?? 100);

    const subscribe = () => {
        window.addEventListener("resize", _cb);
    };

    const unsubscribe = () => {
        window.removeEventListener("resize", _cb);
    };

    $mount(() => {
        subscribe();

        $cleanup(() => {
            unsubscribe();
        });
    });

    return { windowSize: $get(size), subscribe, unsubscribe };
};

