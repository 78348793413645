/** @jsxRuntime automatic */
/** @jsxImportSource solid-js */
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps } from "solid-js";

interface Props extends AttributeStyleProps, ComponentProps<"ul"> {
    className?: string;
}

const UnorderedList: Component<Props> = (props) => {
    const { children, className, ...other } = $destructure(props);
    const { style, classes, rest } = useAttributeStyle(other);

    return (
        <ul class={cn(List, classes, className)} style={style} {...rest}>
            {children}
        </ul>
    );
};

const List = css`
    display: flex;
    flex-direction: column;
    list-style-type: disc;

    padding: 0 0 0 32px;
    margin-left: initial;
`;

export default UnorderedList;
