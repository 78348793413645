import { Component, JSX } from "solid-js";

interface Props {
    key: any;
    children?: JSX.Element;
}

const Keyed: Component<Props> = (props) => {
    const { children, key } = $destructure(props);
    let visible = $signal(true);
    let firstRun = true;

    $effect(
        $on(key, () => {
            if (firstRun) {
                firstRun = false;
                return;
            }
            visible = false;
            queueMicrotask(() => {
                visible = true;
            });
        })
    );

    return <Show when={visible}>{children}</Show>;
};

export default Keyed;
