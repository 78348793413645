import PlainButton from "@client/Design/Button/PlainButton";
import CompactDropdown from "@client/Design/Dropdown/CompactDropdown";
import Dropdown from "@client/Design/Dropdown/Dropdown.solid";
import IconText from "@client/Design/IconText.solid";
import Input from "@client/Design/Input/Input.solid";
import Text from "@client/Design/Text";
import { useQueryParam } from "@client/hooks/useQueryParam.solid";
import { IS_NATIVE } from "@client/native/platform";
import { breakpointFrom, color, heading2Text } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import { useNavigate } from "@solidjs/router";
import cn from "classnames";
import { Component } from "solid-js";

const subjects: [string, string][] = [
    ["words", "hira-a"],
    ["kanji", "kanji"],
    ["sentences", "message"],
    ["lessons", "book"],
    ["users", "user"],
];

// maps to subject
const searchPaths = {
    "/": 0,
    "/dictionary/words": 0,
    "/dictionary/kanji": 1,
    "/dictionary/sentences": 2,
    "/dictionary/lessons": 3,
    "/community/users": 4,
};

interface Props {
    focused?: boolean;
    className?: string;
    onSearchbarFocusChange?: (focused: boolean) => void;
}

const MobileHeaderSearchbar: Component<Props> = (props) => {
    const { focused, className, onSearchbarFocusChange } = $destructure(props);
    let selectedSubjectIdx = $signal(0);
    const [urlQuery] = useQueryParam("q");
    let empty = $signal(!urlQuery);

    let searchbarRef!: HTMLInputElement;
    let containerRef!: HTMLFormElement;
    const navigate = useNavigate();

    const handleSearch = (ev?: any) => {
        ev?.preventDefault();
        const query = searchbarRef.value;
        const subject = subjects[selectedSubjectIdx][0];

        const site = subject == "users" ? "community" : "dictionary";

        const suffix = query != "" ? `?q=${encodeURIComponent(query)}` : "";
        navigate(`/${site}/${subject}${suffix}`);
        onSearchbarFocusChange?.(false);
    };

    const handleCrossClick = (ev) => {
        ev.preventDefault();
        searchbarRef.value = "";
        searchbarRef.focus();
    };

    const handleSelect = (idx: number) => {
        selectedSubjectIdx = idx;
        searchbarRef.focus();
    };

    const handleFocus = () => {
        if (!empty) empty = true;
        onSearchbarFocusChange?.(true);
    };

    const handleInput = (ev) => {
        if (empty && ev.currentTarget.value) {
            empty = false;
        } else if (!empty && !ev.currentTarget.value) {
            empty = true;
        }
    };

    const handleKeyDown = (ev: KeyboardEvent) => {
        switch (ev.key) {
            case "Enter": {
                handleSearch();
                searchbarRef.blur();
                break;
            }
            case "Escape": {
                onSearchbarFocusChange?.(false);
                break;
            }
        }
    };

    $effect(() => {
        if (focused) {
            searchbarRef.value = "";
            return;
        }
        searchbarRef.blur();
        searchbarRef.value =
            urlQuery() || `search ${subjects[selectedSubjectIdx][0]}`;
    });

    return (
        <form
            ref={containerRef}
            class={cn(_MobileHeaderSearchbar, className, {
                focused,
                native: IS_NATIVE,
            })}
            onSubmit={handleSearch}>
            <CompactDropdown
                className={StyledDropdown}
                selectedItem={
                    <div class={DropdownItem}>
                        <IconText icon={subjects[selectedSubjectIdx][1]} />
                    </div>
                }
                onSelect={handleSelect}
                items={subjects.map(([text, icon]) => (
                    <div class={DropdownItem}>
                        <IconText icon={icon} />
                        <Text>{text}</Text>
                    </div>
                ))}
                selectedItemIdx={selectedSubjectIdx}
            />
            <Input
                ref={(r) => {
                    searchbarRef = r;
                }}
                className={HeaderSearchbar}
                placeholder={`search ${subjects[selectedSubjectIdx][0]}`}
                initialValue={urlQuery()}
                name="q"
                spellcheck={false}
                autocomplete="off"
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
            />
            <div class={cn(Actions, { active: focused, empty })}>
                <PlainButton
                    width="32"
                    onClick={handleCrossClick}
                    disabled={empty}>
                    <IconText onClick={handleCrossClick} icon="cross" />
                </PlainButton>
                <PlainButton width="32" type="submit" disabled={empty}>
                    <IconText icon="search" />
                </PlainButton>
            </div>
        </form>
    );
};

const StyledDropdown = css`
    width: 0;
    opacity: 0;
    margin: 5px 0 7px;
    transition: 200ms ease-in-out;
    transition-property: opacity, width;
`;

const Actions = css`
    height: 48px;
    width: 0;
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 5px 0 7px;
    transition-property: opacity, width;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    transition-delay: 0ms;
    overflow: hidden;
    ${heading2Text}
    &.active {
        opacity: 1;
        width: ${36 * 2 + 16}px;
        transition-delay: 200ms;
    }
    &.empty {
        color: ${color("colors/text-100a")};
    }
`;

const totalIconSpace = 27 + 60 + 27 + 22 + 60 + 22 + 48 + 32;
const totalIconSpaceNative = 27 + 60 + 27 + 22 + 48 + 32;

const _MobileHeaderSearchbar = css`
    position: absolute;
    left: 114px;
    z-index: 200;
    width: calc(100vw - ${totalIconSpace}px);
    &.native {
        width: calc(100vw - ${totalIconSpaceNative}px);
    }
    display: flex;
    align-items: center;
    padding: 0;
    background: ${color("colors/primary-300")};
    ${heading2Text}
    ${breakpointFrom("s")} {
        display: none;
    }
    transition-property: left, width, padding;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    ${Dropdown.styles.CurrentOption} {
        background: ${color("colors/primary-300")};
    }
    ${Dropdown.styles.Options} {
        background: ${color("colors/primary-50")};
    }
    ${Dropdown.styles.Option}:hover {
        color: ${color("colors/text-900a")};
        background: ${color("colors/primary-100")};
    }
    ${Dropdown.styles.SelectedOption} {
        background: ${color("colors/primary-200")};
    }

    &.focused {
        left: 32px;
        width: calc(100% - 64px);
        padding: 0 20px;
        transition-delay: 200ms;
        background: ${color("colors/primary-200")};

        ${Input.styles.Container} {
            background: ${color("colors/primary-200")};
        }

        .${StyledDropdown} {
            width: 80px;
            opacity: 1;
            transition-delay: 200ms;
        }

        ${Actions} {
            width: 72px;
            opacity: 1;
            transition-delay: 200ms;
        }
    }
`;

const HeaderSearchbar = css`
    width: 100%;
    box-shadow: none !important;
    &:not(:focus) {
        color: ${color("colors/text-100a")};
    }
`;

const DropdownItem = css`
    display: flex;
    gap: 8px;
`;

export default MobileHeaderSearchbar;
