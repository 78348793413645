import Icon from "@client/Design/Icon.solid";
import { useAppCtx } from "@client/context/AppContext";
import MobileHeaderSearchbar from "@client/header/MobileHeaderSearchbar.solid";
import { IS_NATIVE } from "@client/native/platform";
import { breakpointFrom } from "@client/style/commonStyle";
import { errorToast } from "@core/util/react/toast";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";
import { isServer } from "solid-js/web";

interface Props {
    searchbarFocused?: boolean;
    onSearchbarFocusChange?: (focus: boolean) => void;
}

const MobileHeaderContent: Component<Props> = (props) => {
    const { searchbarFocused, onSearchbarFocusChange } = $destructure(props);
    let fullscreen = $signal(!isServer ? !!document.fullscreenElement : false);

    $mount(() => {
        const handler = () => {
            fullscreen = !!document.fullscreenElement;
        };
        document.addEventListener("fullscreenchange", handler);
        $cleanup(() =>
            document.removeEventListener("fullscreenchange", handler)
        );
    });

    const appCtx = useAppCtx();
    let drawerOpen = $derefSignal(appCtx.drawerOpen);

    const handleMenuFullscreenClick = async (ev: MouseEvent) => {
        try {
            if (document.fullscreenElement) {
                await document.exitFullscreen();
                fullscreen = false;
            } else {
                await document.documentElement.requestFullscreen({
                    navigationUI: "hide",
                });
                fullscreen = true;
            }
        } catch (err) {
            errorToast(err);
        }
    };

    const handleMenuButtonClick = (ev: MouseEvent) => {
        ev.preventDefault();
        drawerOpen = true;
    };

    return (
        <>
            <Icon
                icon="menu"
                onClick={handleMenuButtonClick}
                className={cn(HeaderIcon, menuIconStyle, {
                    transparent: searchbarFocused,
                })}
            />

            <MobileHeaderSearchbar
                focused={searchbarFocused}
                onSearchbarFocusChange={onSearchbarFocusChange}
            />

            <Show when={!IS_NATIVE}>
                <Icon
                    icon={fullscreen ? "fullscreen-exit" : "fullscreen"}
                    className={cn(HeaderIcon, fullscreenIconStyle, {
                        transparent: searchbarFocused,
                    })}
                    onClick={handleMenuFullscreenClick}
                />
            </Show>
        </>
    );
};

const HeaderIcon = css`
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    ${breakpointFrom("s")} {
        display: none;
    }
    cursor: pointer;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    transition-delay: 200ms;

    &.transparent {
        opacity: 0;
        transition-delay: 0ms;
    }
`;

const menuIconStyle = css`
    margin: 0 auto 0 -5px;
`;

const fullscreenIconStyle = css`
    margin-right: -10px;
`;

export default MobileHeaderContent;
