import NotFound from "@client/errorPages/NotFound";
import AuthRoute from "@client/routing/AuthRoute";
import { Navigate } from "@solidjs/router";
import { lazy } from "solid-lazy-plus";

const StreamListSiteSolid = lazy(
    () => import("@client/study/podcastStreams/StreamListSite.solid")
);
const StreamFeedSiteSolid = lazy(
    () => import("@client/study/podcastStreams/streamFeed/StreamFeedSite")
);
const QuizSite = lazy(() => import("@client/quiz/QuizSite"));

export const studySite = {
    path: "/study",
    children: [
        {
            path: "/streams/feed",
            component: (props) => <StreamFeedSiteSolid {...props} />,
        },
        {
            path: "/streams",
            component: (props) => <StreamListSiteSolid {...props} />,
        },
        //             <AuthRoute
        //                 path="/study/recordings"
        //                 exact={true}
        //                 role={UserRole.Admin}
        //                 type="usersOnly"
        //                 render={() => <RecordingsSite />}
        //             />
        {
            path: "/quiz/*quizName",
            component: (props) => (
                <AuthRoute>
                    <QuizSite {...props} />
                </AuthRoute>
            ),
        },
        {
            path: "/",
            component: (props) => <Navigate href="/study/streams" />,
        },
        {
            path: "*",
            component: (props) => <NotFound />,
        },
    ],
};
