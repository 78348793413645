import { useStreamCtx } from "@client/context/StreamContext";
import { Component } from "solid-js";

interface Props {}

const LazySeekbar: Component<Props> = (props) => {
    let seekbarComponent = $signal<Component | undefined>();

    const streamCtx = useStreamCtx();
    streamCtx.playStream = async (workflowName, parameters) => {
        const m = await import("@client/seekbar/Seekbar");
        seekbarComponent = m.default;
        // at this point the seekbar is mounted and registered the handler
        await streamCtx.playStream!(workflowName, parameters);
    };

    return (
        <Show when={seekbarComponent}>
            <Dynamic component={seekbarComponent!} />
        </Show>
    );
};

export default LazySeekbar;
