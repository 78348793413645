import IconText from "@client/Design/IconText.solid";
import Text from "@client/Design/Text";
import { breakpoint, subText, text } from "@client/style/commonStyle";
import { css } from "@linaria/core";

export interface Props {
    name: string;
    role: string;
    profilePicturePath: string;
    email?: string;
    website?: string;
    githubName?: string;
    ldGender: "male" | "female";
}

const listingItemMap = {
    githubName: [
        "github",
        (value: string) => (
            <a
                href={`https://github.com/${value}`}
                target="_blank"
                rel="noreferrer">
                {value}
            </a>
        ),
    ],
    website: [
        "globe",
        (value: string) => (
            <a href={`https://${value}`} target="_blank" rel="noreferrer">
                {value}
            </a>
        ),
    ],
    email: ["email"],
};

const AuthorCard: Component<Props> = (props) => {
    const {
        name,
        email,
        role,
        githubName,
        website,
        profilePicturePath,
        ldGender,
    } = props;

    // const jsonLd = <JsonLd<OrganizationRole & { member: Person }> item={{
    //     "@context": "https://schema.org",
    //     "@type": "OrganizationRole",
    //     "name": role.split(",").map(v => v.trim()),
    //     "member": {
    //         "@type": "Person",
    //         name,
    //         email,
    //         "jobTitle": role.split(",").map(v => v.trim()),
    //         "image": profilePicturePath,
    //         "sameAs": [website].filter(isPresent),
    //         "gender": ldGender,
    //         "memberOf": {
    //             "@type": "Organization",
    //             "name": "fujiPod",
    //         },
    //     },
    // }}/>;

    return (
        <div class={_AuthorCard}>
            {/*{jsonLd}*/}
            <img
                class={ProfilePicture}
                alt={`author profile picture`}
                src={profilePicturePath}
            />
            <h1 class={Name}>{name}</h1>
            <h2 class={Role}>{role}</h2>

            <div class={HandleList}>
                {Object.entries({ githubName, email, website })
                    .filter(([, v]) => v != null)
                    .map(([key, value]) => {
                        const [iconName, transformer] = listingItemMap[key];
                        return (
                            <>
                                <IconText icon={iconName} />
                                <Text>{transformer?.(value) ?? value}</Text>
                            </>
                        );
                    })}
            </div>
        </div>
    );
};

const _AuthorCard = css`
    display: grid;
    grid-template:
        "picture name" 1fr
        "picture role" auto
        "handleList handleList" auto
        / auto 1fr;
`;

const ProfilePicture = css`
    grid-area: picture;
    border-radius: 2px;
    width: 60px;
    margin: 0 16px 0 0;
`;

const Name = css`
    grid-area: name;
    white-space: nowrap;
    ${text("primary", "heading3", "link-primary-600")}
`;

const Role = css`
    grid-area: role;
    white-space: nowrap;
    ${text("primary", "body", "colors/text-600a")}
    ${breakpoint("xs")} {
        ${subText}
    }
`;

const HandleList = css`
    grid-area: handleList;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    margin-top: 8px;
    grid-row-gap: 4px;
    grid-column-gap: 8px;

    ${text("primary", "sub", "colors/text-600a")}
`;

// const HandleIcon = styled(SVG)`
//   fill: currentColor;
//   align-self: center;
// `;

export default AuthorCard;
