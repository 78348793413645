import { HTMLProps } from "@core/util/solid/HTMLProps.solid";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props extends AttributeStyleProps, HTMLProps<"button"> {
    className?: string;
}

const PlainButton: Component<Props> = (props) => {
    const { className, children, ...other } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <button
            style={style}
            class={cn(plainStyle, classes, className)}
            {...rest}>
            {children}
        </button>
    );
};

const plainStyle = css`
    width: auto;
    -moz-appearance: none;
    -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
    -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */

    &,
    &:focus,
    &:active {
        background: none;
        border: none;
        outline: none !important;
        border-radius: initial;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
    }

    /* Mozilla uses a pseudo-element to show focus on buttons, */
    /* but anchors are highlighted via the focus pseudo-class. */
    @supports (-moz-appearance: none) {
        /* Mozilla-only */
        &::-moz-focus-inner {
            /* reset any predefined properties */
            border: none;
            padding: 0;
        }

        &:focus {
            /* add outline to focus pseudo-class */
            outline-style: dotted;
            outline-width: 1px;
        }
    }
`;

export default PlainButton;
