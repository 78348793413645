import { Component, JSX } from "solid-js";
// import "@client/global.scss";
// import LazyAppDrawer from "@client/Design/LazyAppDrawer.solid";
// import ToastContainer from "@client/Design/toast/ToastContainer";
// import Loader from "@client/Loader";
// import AppSpacer from "@client/app/AppSpacer.solid";
// import { useAppCtx } from "@client/context/AppContext";
// import GeneralError from "@client/errorPages/GeneralError";
// import NotFound from "@client/errorPages/NotFound";
// import Footer from "@client/footer/Footer.solid";
// import PortalContainer from "@client/modal/PortalContainer";
// import AuthRoute from "@client/routing/AuthRoute";
// import SearchbarWithContent from "@client/searchBar/SearchbarWithContent";
// import LazySeekbar from "@client/seekbar/LazySeekbar.solid";
// import NoSSR from "@client/shared/NoSSR";
import AppSpacer from "@client/app/AppSpacer.solid";
import BackgroundFish from "@client/BackgroundFish";
import LazyAppDrawer from "@client/Design/LazyAppDrawer.solid";
import ToastContainer from "@client/Design/toast/ToastContainer";
import GeneralError from "@client/errorPages/GeneralError";
import Footer from "@client/footer/Footer.solid";
import Header from "@client/header/Header";
import PortalContainer from "@client/modal/PortalContainer";
import LazySeekbar from "@client/seekbar/LazySeekbar";
import { breakpointFrom } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import { clientOnly } from "@solidjs/start";
// import { nativeOTAUpdateAccept } from "@client/native/nativeOTAUpdate";
import { SplashScreen } from "@capacitor/splash-screen";

const Loader = clientOnly(() => import("@client/Loader"));

// import "@client/shared/markdown/MarkdownRender.solid";

// const AboutSite = lazy(() => import("@client/about/AboutSite"));
// const DashboardSite = lazy(() => import("@client/dashboard/DashboardSite"));
// const CommunitySite = lazy(() => import("@client/community/CommunitySite"));
// const DictionarySite = lazy(() => import("@client/dictionary/DictionarySite"));
// const NewsletterUnsubscribeSite = loadable(() => import( "@client/email/NewsletterUnsubscribeSite"));
// const PasswordResetSite = lazy(
//     () => import("@client/passwordReset/PasswordResetSite")
// );
// const PrivacyPolicySite = lazy(() => import("@client/PrivacyPolicySite"));
// const SignupSite = lazy(() => import("@client/signup/SignupSite"));
// const SettingsSite = lazy(() => import("@client/settings/SettingsSite"));
// const WelcomeSite = lazy(() => import("@client/welcome/WelcomeSite"));
// // const StudySite = loadable(() => import( "@client/study/StudySite"));

// const NotFoundWithSearchbarFragment: Component = (props) => {
//     return (
//         <SearchbarWithContent>
//             <NoSSR>
//                 <NotFound/>
//             </NoSSR>
//         </SearchbarWithContent>
//     );
// };

interface Props {
    children?: JSX.Element;
}

const App: Component<Props> = (props) => {
    $effect(() => {
        (async () => {
            // await nativeOTAUpdateAccept();
            await SplashScreen.hide();
        })();
    });

    return (
        <>
            <div class={_App}>
                <ErrorBoundary fallback={(err) => <GeneralError error={err} />}>
                    <PortalContainer />
                    <ToastContainer />
                    <LazyAppDrawer />
                    <Header />
                    <BackgroundFish />

                    <Suspense fallback={<Loader />}>{props.children}</Suspense>

                    <LazySeekbar />
                    {/**/}
                    {/*     <Suspense fallback={<Loader />}> */}
                    {/*         <Routes> */}
                    {/*             <Route path="/" element={<DashboardSite />} /> */}
                    {/*             <Route path="/signup" element={<SignupSite />} /> */}
                    {/*             <Route */}
                    {/*                 path="/dictionary/*" */}
                    {/*                 element={<DictionarySite />} */}
                    {/*             /> */}
                    {/*             <Route */}
                    {/*                 path="/community/*" */}
                    {/*                 element={<CommunitySite />} */}
                    {/*             /> */}
                    {/*             <Route path="/about/*" element={<AboutSite />} /> */}
                    {/*             <Route path="/welcome" element={<WelcomeSite />} /> */}
                    {/*             <AuthRoute */}
                    {/*                 path="/settings" */}
                    {/*                 component={() => ( */}
                    {/*                     <NoSSR> */}
                    {/*                         <SettingsSite /> */}
                    {/*                     </NoSSR> */}
                    {/*                 )} */}
                    {/*             /> */}
                    {/*             <Route */}
                    {/*                 path="/password-reset" */}
                    {/*                 element={<PasswordResetSite />} */}
                    {/*             /> */}
                    {/*             <Route */}
                    {/*                 path="/password-change" */}
                    {/*                 element={<PasswordResetSite />} */}
                    {/*             /> */}
                    {/*             <Route path="*" component={() => <NotFound />} /> */}
                    {/*         </Routes> */}
                    {/*     </Suspense> */}
                </ErrorBoundary>
                <AppSpacer />
            </div>
            {/* <Show when={appCtx.online[0]()}> */}
            <Footer />
            {/* </Show> */}
        </>
    );
};

const _App = css`
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;

    ${breakpointFrom("m")} {
        min-height: 100vh;
    }
`;

export default App;
