import SSRContextProvider from "@client/context/SSRContextProvider";
import { SearchbarContextProvider } from "@client/context/SearchbarContext";
import { StartClient } from "@solidjs/start/client";

const ClientApp = () => {
    return (
        <SSRContextProvider>
            <SearchbarContextProvider>
                <StartClient />
            </SearchbarContextProvider>
        </SSRContextProvider>
    );
};

export default ClientApp;
