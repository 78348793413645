import IconText from "@client/Design/IconText.solid";
import Link from "@client/Design/Link/Link";
import Text from "@client/Design/Text";
import { useAppCtx } from "@client/context/AppContext";
import Portal from "@client/modal/Portal.solid";
import {
    boxShadow,
    breakpointUntil,
    color,
    heading3Text,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";

export interface Toast {
    title: string;
    body?: string;
    type?: "default" | "success" | "error";
    link?: { text: string; to: string };
    time?: number | false;
}

type OnToastAdd = (() => void) | null;
let onToastAdd: OnToastAdd = null;

const queue: Toast[] = [];
const getNextToast = () => {
    if (!queue.length) return null;
    return queue.splice(0, 1)[0];
};
export const toast = (toast: Toast) => {
    queue.push(toast);
    onToastAdd?.();
};
//
interface Props {}

const ToastContainer: Component<Props> = (props) => {
    const appCtx = useAppCtx();
    const seekbarVisible = $derefSignal(appCtx.seekbarVisible);

    let toast = $signal<Toast | null>(null);
    const handleToastAdd = () => {
        if (toast) return;
        toast = queue.splice(0, 1)[0];
    };

    // useEffect(() => {
    //     const time = toast?.time ?? 5000;
    //     if (time === false) return;
    //
    //     const timeout = window.setTimeout(() => {
    //         setToast(null);
    //     }, time);
    //     return () => {window.clearTimeout(timeout)};
    // }, [toast]);

    $mount(() => {
        onToastAdd = handleToastAdd;
        $cleanup(() => {
            onToastAdd = null;
        });
    });

    const handleDismiss = () => {
        toast = null;
    };

    const handleDisplayNextToast = () => {
        toast = getNextToast();
    };

    return (
        <Portal>
            {/*<Fade onHidden={handleDisplayNextToast}>*/}
            {!!toast && (
                <>
                    <div
                        class={cn(_ToastContainer, {
                            [toast.type ?? "default"]: true,
                            seekbarVisible,
                        })}
                        onClick={handleDismiss}>
                        <div class={TitleRow}>
                            <Text className={Title}>{toast.title}</Text>
                            <IconText icon="cross" />
                        </div>
                        <Show when={toast.body}>
                            <div class={Body}>
                                <For each={toast.body!.split("\n")}>
                                    {(line, idx) => (
                                        <>
                                            {idx() != 0 && <br />}
                                            {line}
                                        </>
                                    )}
                                </For>
                            </div>
                        </Show>
                        <Show when={toast.link}>
                            <Link
                                to={toast.link!.to}
                                className={cn(ToastLink, {
                                    [toast.type ?? "default"]: true,
                                })}>
                                {toast.link!.text}
                            </Link>
                        </Show>
                    </div>
                </>
            )}
            {/*</Fade>*/}
        </Portal>
    );
};

const bottomOffset = 32;

const _ToastContainer = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    z-index: 200;
    bottom: ${bottomOffset}px;
    right: 32px;
    max-width: 400px;
    padding: 8px 16px 16px;
    border-radius: 4px;
    background: ${color("colors/primary-50")};
    ${boxShadow(0, 4, 4, "rgba(0, 0, 0, 0.1)")}
    cursor: pointer !important;
    user-select: none;
    &.error {
        background: ${color("colors/secondary-100")};
    }

    &.seekbarVisible {
        bottom: ${bottomOffset + 44}px;
        ${breakpointUntil("m")} {
            bottom: ${bottomOffset + 72}px;
        }
        ${breakpointUntil("s")} {
            bottom: ${bottomOffset + 80}px;
        }
    }
`;

const TitleRow = css`
    display: flex;
    align-self: stretch;
    gap: 16px;
    ${heading3Text}
`;

const Title = css`
    flex: 1;
    color: ${color("colors/text-900a")};
`;

const Body = css`
    margin: 4px 0 0;
`;

const ToastLink = css`
    display: inline;
    margin: 8px 0 0;
    &.error {
        color: ${color("link-secondary-600")};
    }
`;

export default ToastContainer;
