import LinkButton from "@client/Design/Link/LinkButton.solid";
import {
    ErrorBanner,
    ErrorBannerSub,
    ErrorReason,
    HomeButton,
    TextContainer,
} from "@client/errorPages/ErrorPages.style";
import { color, subText } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";

interface Props {
    error?: Error;
}

const GeneralError: Component<Props> = (props) => {
    // const {staticContext} = $destructure(props);
    const error = $memo(
        (props.error as any)?.shape?.data ?? (props.error as Error)
    );

    $mount(() => {
        // logger.error("other", "general error", {err: props.error});
        console.error(props.error);
    });

    // if (isDefined(staticContext)) staticContext.statusCode = 500;

    // handle 404 errors from the API
    // const isNotFoundError = (originalError as any)
    //     .graphQLErrors
    //     .some(err => (err as GraphQLError)
    //         .extensions?.code == NotFoundError.code);
    // if (isNotFoundError) {
    //     if (isDefined(staticContext)) staticContext.statusCode = 404;
    //     return <NotFound />;
    // }

    return (
        <div class={TextContainer}>
            <h1 class={ErrorBanner}>Something went wrong</h1>
            <h2 class={ErrorBannerSub}>There was an error</h2>
            <div class={ErrorReason}>
                {(IS_DEV && (
                    <>
                        {error?.message ?? "no error message"}
                        <br />
                        <code class={StackBlock}>
                            {error?.stack
                                ?.split("\n")
                                .map((line: string, idx) => (
                                    <div
                                        class={cn(StackLine, {
                                            inCode: !line.includes(
                                                "/node_modules"
                                            ),
                                        })}>
                                        {line}
                                    </div>
                                ))}
                        </code>
                    </>
                )) || (
                    <>
                        Please try again later or ask for help on the Discord
                        channel.
                    </>
                )}
            </div>
            <LinkButton className={HomeButton} to="/">
                Back to dashboard
            </LinkButton>
        </div>
    );
};

const StackBlock = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 16px 0;
    ${subText}
    text-align: left;
`;

const StackLine = css`
    color: ${color("colors/text-100a")};
    &.inCode {
        color: ${color("colors/text-300a")};
    }
    &:hover {
        color: ${color("colors/text-600a")};
    }
`;

export default GeneralError;
