import Dropdown from "@client/Design/Dropdown/Dropdown.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps } from "solid-js";

interface Props extends ComponentProps<typeof Dropdown> {}

const CompactDropdown: Component<Props> = (props) => {
    return (
        <Dropdown
            {...props}
            className={cn(_CompactDropdown, props.className)}
        />
    );
};

const _CompactDropdown = css`
    ${Dropdown.styles.CurrentOption} {
        padding: 0 4px 0 8px;
    }
`;

export default CompactDropdown;
