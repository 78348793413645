import { Browser } from "@capacitor/browser";
import { LinkInput } from "@client/Design/Link/Link.style";
import RouterLink from "@client/Design/Link/RouterLink.solid";
import { IS_NATIVE } from "@client/native/platform";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { mainConfig } from "@config/main.config";
import { Component, ComponentProps, JSX } from "solid-js";

const isInternalLink = (to: string) => !to.startsWith("http");

interface Props extends ComponentProps<typeof RouterLink>, AttributeStyleProps {
    children?: JSX.Element;
    className?: string;
    to: string;
}

const Link: Component<Props> = (props) => {
    const { children, className, to, onClick, ...other } = $destructure(props);
    const { style, classes, rest } = useAttributeStyle(other);

    const internal = $memo(isInternalLink(to));
    // const component = internal ? LinkInput : PlainLinkInput;
    const component = $memo(internal ? RouterLink : "a");

    const _targetProps: any = () => {
        if (IS_NATIVE && (!internal || rest.target == "_blank")) {
            return {
                onClick: (ev) => {
                    ev.preventDefault();
                    const url = internal
                        ? `${mainConfig.resourceBaseURL}${to}`
                        : to;
                    if (url) {
                        Browser.open({ url }).finally();
                    }
                    if (typeof onClick == "function") {
                        onClick?.(ev);
                    }
                },
                href: to,
            };
        }

        return internal ? { to } : { href: to };
    };

    const targetProps = $memo(_targetProps());

    return (
        <Dynamic
            component={component}
            href={to}
            style={style}
            class={cn(Container, classes, LinkInput, className)}
            onClick={onClick}
            {...rest}
            {...targetProps}>
            {children}
        </Dynamic>
    );
};

const Container = css``;

export default Link;
