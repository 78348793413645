import AboutFujiPodSite from "@client/about/AboutFujiPodSite";
import DonateSite from "@client/about/DonateSite";
import NativeAppsSite from "@client/about/NativeAppsSite.solid";
import NotFound from "@client/errorPages/NotFound";
import { lazy } from "solid-lazy-plus";
import { Navigate } from "@solidjs/router";

const FAQSite = lazy(() => import("@client/faq/FAQSite"));

interface Props {}
//
// const AboutSite: Component<Props> = (props) => {
//     return (
//         <Routes>
//             <Route path="/apps" component={() => <NativeAppsSite />} />
//             <Route path="/donate" component={() => <DonateSite />} />
//             <Route path="/faq" component={() => <FAQSite />} />
//             <Route path="/fujipod" component={() => <AboutFujiPodSite />} />
//             <Route
//                 path="/"
//                 component={() => <Navigate href="/about/fujipod" />}
//             />
//             <Route path="*" component={() => <NotFound />} />
//         </Routes>
//     );
// };

export const aboutSite = {
    path: "/about",
    children: [
        {
            path: "/fujipod",
            component: (props) => <AboutFujiPodSite />,
        },
        {
            path: "/donate",
            component: (props) => <DonateSite />,
        },
        {
            path: "/apps",
            component: (props) => <NativeAppsSite />,
        },
        {
            path: "/faq",
            component: (props) => <FAQSite />,
        },
        {
            path: "/",
            component: (props) => <Navigate href="/about/fujipod" />,
        },
        {
            path: "*",
            component: (props) => <NotFound />,
        },
    ],
};
