import GeneralError from "@client/errorPages/GeneralError";
import { useQueryParam } from "@client/hooks/useQueryParam.solid";
import SearchBar from "@client/searchBar/Searchbar";
import { breakpointUntil, contentContainer } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import { useNavigate } from "@solidjs/router";
import cn from "classnames";
import { Component, ComponentProps, JSX, Suspense } from "solid-js";
import { clientOnly } from "@solidjs/start";

const Loader = clientOnly(() => import("@client/Loader"));

interface Props extends ComponentProps<typeof SearchBar> {
    children: JSX.Element;
    wide?: boolean;
}

const SearchbarWithContent: Component<Props> = (props) => {
    const { activeSearchSubject, wide, children } = $destructure(props);
    const [q] = useQueryParam("q", "string");

    const navigate = useNavigate();

    const handleSubmit = (query, subject) => {
        const site = subject == "users" ? "community" : "dictionary";
        const suffix = query ? `?q=${encodeURIComponent(query)}` : "";
        navigate(`/${site}/${subject}${suffix}`);
    };

    return (
        <div class="w-full">
            <SearchBar
                className={cn(StyledSearchBar)}
                activeSearchSubject={activeSearchSubject}
                initialQuery={q() ?? undefined}
                onSubmit={handleSubmit}
            />
            <div class={cn(SearchbarContent, { wide })}>
                <ErrorBoundary fallback={(err) => <GeneralError error={err} />}>
                    <Suspense fallback={<Loader />}>{children}</Suspense>
                </ErrorBoundary>
            </div>
        </div>
    );
};

const SearchbarContent = css`
    &:not(.wide) {
        ${contentContainer}
    }
`;

const StyledSearchBar = css`
    ${breakpointUntil("s")} {
        display: none;
    }
`;

export default SearchbarWithContent;
