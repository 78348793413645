import { AppContext } from "@client/context/AppContext";
import SVG from "@client/shared/SVG";
import { bodyText, color, primaryFont } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, useContext } from "solid-js";

interface Props {
    className?: string;
}

const ThemeToggle: Component<Props> = (props) => {
    const { className } = $destructure(props);
    let theme = $derefSignal(useContext(AppContext).theme);

    const handleClick = () => {
        theme = theme == "light" ? "dark" : "light";
    };

    return (
        <div class={cn(className, Container)} onClick={handleClick}>
            <div class={BackDrop} />
            <div
                data-cy="theme-toggle-circle"
                class={cn(Circle, { isRight: theme == "light" })}>
                <SVG
                    class={ThemeIcon}
                    src={`/assets/resources/static/${theme}-theme-icon.svg`}
                />
            </div>
        </div>
    );
};

const containerHeight = 32;
const containerWidth = 52;
const backdropHeight = 24;
const backdropWidth = 48;

const Container = css`
    position: relative;
    height: ${containerHeight}px;
    width: ${containerWidth}px;
    cursor: pointer;
    user-select: none;
    ${bodyText}
    ${primaryFont}
`;

const BackDrop = css`
    position: absolute;
    height: ${backdropHeight}px;
    width: ${backdropWidth}px;
    left: ${(containerWidth - backdropWidth) / 2}px;
    top: ${(containerHeight - backdropHeight) / 2}px;
    border-radius: ${backdropHeight}px;
    background: ${color("colors/primary-200")};
    z-index: 2;
`;

const Circle = css`
    position: absolute;
    height: ${containerHeight}px;
    width: ${containerHeight}px;
    border-radius: ${containerHeight}px;
    background: ${color("colors/primary-500")};
    z-index: 5;
    right: ${containerWidth - containerHeight}px;
    transition: right 50ms ease-in-out;

    &.isRight {
        right: 0;
    }
`;

const ThemeIcon = css`
    position: absolute;
    width: 60%;
    height: 60%;
    top: 20%;
    left: 20%;
    fill: ${color("colors/text-300b")};
`;

export default ThemeToggle;
