import Link from "@client/Design/Link/Link";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps } from "solid-js";

interface Props extends ComponentProps<typeof Link> {}

const PlainLink: Component<Props> = (props) => {
    const { className, ...rest } = $destructure(props);

    return <Link className={cn(_PlainLink, className)} {...rest} />;
};

const _PlainLink = css`
    @layer c {
        &,
        &:visited,
        &:hover,
        &:active {
            color: inherit;
            text-decoration: none;
        }
    }
`;

export default PlainLink;
