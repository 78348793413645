import {Toast, toast} from "@client/Design/toast/ToastContainer";


const toastBase = (_toast: Toast) => {
    return toast(_toast);
};

type Options = Omit<Toast, "type" | "title">;

export const errorToast = (title: string, options: Options = {}) =>
    toastBase({type: "error", title, ...options});

export const successToast = (title: string, options: Options = {}) =>
    toastBase({type: "success", title, ...options});