import IconText from "@client/Design/IconText.solid";
import Input from "@client/Design/Input/Input.solid";
import { primaryInputStyle } from "@client/Design/Input/Input.style";
import PlainLink from "@client/Design/Link/PlainLink";
import Pill from "@client/Design/Pill/Pill";
import Text from "@client/Design/Text";
import { SearchbarContext } from "@client/context/SearchbarContext";
import { useQueryParam } from "@client/hooks/useQueryParam.solid";
import RadicalSearchPopup from "@client/searchBar/RadicalSearchPopup";
import {
    breakpoint,
    color,
    heading2Text,
    withStyle,
} from "@client/style/commonStyle";
import { useOnClickOutside } from "@core/util/solid/useOnClickOutside.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, Show, useContext } from "solid-js";

export type SearchSubject =
    | "words"
    | "kanji"
    | "sentences"
    | "lessons"
    | "users";

const getWordsSubjectLink = (
    subject: SearchSubject | undefined,
    query: string
) => {
    if (subject == "words" || !query) return `/dictionary/words`;
    return `/dictionary/words?q=${query}`;
    // if (!user || user.wordStage == 1) return `/dictionary/words`;
    //
    // const page = Math.floor((user.stage / (mainConfig.wordIndexSite.resultsPerPage + 1)) + 1);
    // return `/dictionary/words?page=${page}`;
};

const getKanjiSubjectLink = (
    subject: SearchSubject | undefined,
    query: string
) => {
    if (subject == "kanji" || !query) return `/dictionary/kanji`;
    return `/dictionary/kanji?q=${query}`;
    // if (!user || user.stage == 1) return `/dictionary/kanji`;
    //
    // const page = Math.floor((user.stage / (mainConfig.kanjiIndexSite.resultsPerPage + 1)) + 1);
    // return `/dictionary/kanji?page=${page}`;
};

const getSentenceSubjectLink = (
    subject: SearchSubject | undefined,
    query: string
) => {
    if (subject == "sentences" || !query) return `/dictionary/sentences`;
    return `/dictionary/sentences?q=${query}`;
};

const getLessonSubjectLink = (
    subject: SearchSubject | undefined,
    query: string
) => {
    if (subject == "lessons" || !query) return `/dictionary/lesson/overview`;
    return `/dictionary/lessons?q=${query}`;
};

const getUserSubjectLink = (
    subject: SearchSubject | undefined,
    query: string
) => {
    if (subject == "users" || !query) return `/community/users`;
    return `/community/users?q=${query}`;
};

interface Props {
    activeSearchSubject?: SearchSubject;
    initialQuery?: string;
    focusOnMount?: boolean;
    className?: string;

    onSubmit?(query: string, subject: SearchSubject): true | void;
    onSubjectChange?: () => void;
}

const Searchbar: Component<Props> = (props) => {
    const {
        activeSearchSubject,
        initialQuery,
        focusOnMount,
        className,
        onSubmit,
        onSubjectChange,
    } = $destructure(props);

    const [q] = useQueryParam("q", "string");

    const searchbarCtx = useContext(SearchbarContext);
    let searchbarTerm = $derefSignal(searchbarCtx.searchbarTerm);
    let searchInputValue = $signal(searchbarTerm);
    let isRadicalSearchOpen = $signal(false);

    let inputFieldRef!: HTMLInputElement;

    $effect(() => {
        searchInputValue = searchbarTerm;
    });
    $effect(() => {
        searchInputValue = q() ?? "";
    });

    $mount(() => {
        if (!initialQuery) return;
        searchbarTerm = initialQuery;
        searchInputValue = initialQuery;
    });

    const handleSearchSubmit = (ev) => {
        ev.preventDefault();
        inputFieldRef.blur();

        if (searchInputValue == null) return;

        const consumed = onSubmit?.(
            searchInputValue,
            activeSearchSubject ?? "words"
        );
        if (consumed) return;
    };

    const handleSearchInputValueChange = (ev) => {
        searchInputValue = ev.currentTarget.value;
        searchbarTerm = ev.target.value;
    };

    const handleRadicalSearchKanjiSelect = (kanji: string) => {
        searchInputValue = `${searchInputValue}${kanji}`;
    };

    let radicalSearchRef!: HTMLDivElement;
    let radicalSearchButtonRef: HTMLButtonElement | undefined;
    useOnClickOutside(
        () => [radicalSearchRef],
        (ev) => {
            if (radicalSearchButtonRef?.contains(ev.target as Element)) return;
            isRadicalSearchOpen = false;
        },
        () => isRadicalSearchOpen
    );

    const handleRadicalSearchButtonClick = (ev) => {
        ev.preventDefault();
        isRadicalSearchOpen = !isRadicalSearchOpen;
    };

    const handleClearButtonClick = () => {
        searchbarTerm = "";
        searchInputValue = "";
        inputFieldRef.focus();
    };

    const handleSubjectLinkClick = () => {
        onSubjectChange?.();
    };

    return (
        <div class={cn(_Searchbar, className)}>
            <div class="pt-7 pb-7 content-container">
                <div class="flex items-baseline">
                    <div
                        class={cn(SearchField, {
                            popupOpen: isRadicalSearchOpen,
                        })}>
                        <form class={SearchForm} onSubmit={handleSearchSubmit}>
                            <Show when={isRadicalSearchOpen}>
                                <RadicalSearchPopup
                                    ref={(r) => {
                                        radicalSearchRef = r;
                                    }}
                                    onKanjiSelect={
                                        handleRadicalSearchKanjiSelect
                                    }
                                />
                            </Show>
                            <Input
                                ref={(r) => {
                                    inputFieldRef = r;
                                }}
                                className={SearchInput}
                                placeholder={`search ${
                                    activeSearchSubject ?? "words"
                                }`}
                                data-cy="header-searchbar-input"
                                spellcheck={false}
                                value={searchInputValue}
                                onInput={handleSearchInputValueChange}
                                focusOnMount={focusOnMount}
                            />

                            <div class="flex items-center gap-2">
                                <Show when={activeSearchSubject != "users"}>
                                    <button
                                        class={ActionButton}
                                        ref={radicalSearchButtonRef}
                                        type="button"
                                        data-cy="header-searchbar-radical"
                                        aria-label="search by radical"
                                        onClick={
                                            handleRadicalSearchButtonClick
                                        }>
                                        <Text>部</Text>
                                    </button>
                                </Show>
                                <button
                                    class={ActionButton}
                                    type="reset"
                                    data-cy="header-searchbar-clear"
                                    aria-label="clear search"
                                    onClick={handleClearButtonClick}>
                                    <IconText
                                        size="heading2"
                                        icon="cross"
                                        description="clear icon"
                                    />
                                </button>
                                <button
                                    class={ActionButton}
                                    type="submit"
                                    data-cy="header-searchbar-submit"
                                    aria-label="submit search">
                                    <IconText
                                        size="heading2"
                                        icon="search"
                                        description="search icon"
                                    />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class={SearchTypeSelection}>
                    <SearchType
                        subject="words"
                        icon="hira-a"
                        to={getWordsSubjectLink(
                            activeSearchSubject,
                            searchInputValue
                        )}
                        active={activeSearchSubject == "words"}
                        onClick={handleSubjectLinkClick}
                    />
                    <SearchType
                        subject="kanji"
                        icon="kanji"
                        to={getKanjiSubjectLink(
                            activeSearchSubject,
                            searchInputValue
                        )}
                        active={activeSearchSubject == "kanji"}
                        onClick={handleSubjectLinkClick}
                    />
                    <SearchType
                        subject="sentences"
                        icon="message"
                        to={getSentenceSubjectLink(
                            activeSearchSubject,
                            searchInputValue
                        )}
                        active={activeSearchSubject == "sentences"}
                        onClick={handleSubjectLinkClick}
                    />

                    <SearchType
                        subject="lessons"
                        icon="book"
                        to={getLessonSubjectLink(
                            activeSearchSubject,
                            searchInputValue
                        )}
                        active={activeSearchSubject == "lessons"}
                        onClick={handleSubjectLinkClick}
                    />

                    <SearchType
                        subject="users"
                        icon="person"
                        to={getUserSubjectLink(
                            activeSearchSubject,
                            searchInputValue
                        )}
                        active={activeSearchSubject == "users"}
                        onClick={handleSubjectLinkClick}
                    />
                </div>
            </div>
        </div>
    );
};

interface SearchTypeProps {
    subject: SearchSubject;
    active?: boolean;
    icon: string;
    onClick?: () => void;
    to?: string;
}

const SearchType: Component<SearchTypeProps> = (props) => {
    const { subject, active, to, icon, onClick } = $destructure(props);
    const site = $memo(subject == "users" ? "community" : "dictionary");

    return (
        <PlainLink
            data-cy={`searchbar-${subject}-type-item`}
            to={to ?? `/${site}/${subject}`}
            onClick={onClick}>
            <Pill display="flex" gap="4" className={cn(HeaderPill, { active })}>
                <IconText icon={icon} />
                {subject}
            </Pill>
        </PlainLink>
    );
};

const _Searchbar = css`
    background: ${color("header/bg")};
`;

const SearchTypeSelection = css`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
`;

const SearchField = css`
    width: 60%;

    border-radius: 4px;
    ${breakpoint("xs")} {
        width: 100%;
    }

    &.popupOpen {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
`;

const SearchForm = css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    ${primaryInputStyle}
    padding: 0 20px 0 0;
    z-index: 300;

    ${Input.styles.Container} {
        background: ${color("colors/primary-300")} !important;
        box-shadow: none !important;
    }
`;

const SearchInput = css`
    width: 0;
    flex: 1 1 auto;
    ${heading2Text}
    box-shadow: none;
    background: transparent;
`;

const ActionButton = css`
    display: flex;
    color: ${color("colors/text-600a")};
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    flex: 0 0 auto;
    ${heading2Text}
`;

const HeaderPill = css`
    background: ${color("header/pill-bg")} !important;
    color: ${color("colors/text-600b")};
    &.active {
        background: ${color("header/pill-bg-active")} !important;
        color: ${color("colors/text-900a")};
    }
`;

export default withStyle(Searchbar, { ActionButton });
