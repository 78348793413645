import { HTMLProps } from "@core/util/solid/HTMLProps.solid";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import cn from "classnames";
import { Component } from "solid-js";

interface Props extends AttributeStyleProps, HTMLProps<"div"> {
    className?: string;
}

const Box: Component<Props> = (props) => {
    const { children, className, ...other } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <div class={cn(classes, className)} style={style} {...rest}>
            {children}
        </div>
    );
};

export default Box;
