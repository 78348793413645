import { color, withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props extends AttributeStyleProps {
    variant?: "horizontal" | "vertical";
    className?: string;
}

const Separator: Component<Props> = (props) => {
    const { variant = "horizontal", className, ...other } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <div
            style={style}
            class={cn(SeparatorContainer, className, variant, classes)}
            {...rest}>
            <div class={cn(SeparatorLine, { [variant]: true })} />
        </div>
    );
};

const SeparatorContainer = css`
    @layer root {
        &.horizontal {
            padding: 1px 0;
        }
        &.vertical {
            align-self: stretch;
            display: flex;
            align-items: stretch;
            margin: 0 10px;
        }
    }
`;

const SeparatorLine = css`
    border-width: 0;
    &.horizontal {
        border-bottom: solid;
        border-color: ${color("colors/primary-200")};
        border-bottom-width: 2px;
    }
    &.vertical {
        border-right: solid;
        border-color: ${color("colors/primary-200")};
        border-right-width: 2px;
    }
`;

export default withStyle(Separator, {
    Container: SeparatorContainer,
    Line: SeparatorLine,
});
