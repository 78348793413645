import { mainConfig } from "@config/main.config";
import { createContext, createSignal, JSX, useContext } from "solid-js";

const searchbarTerm = createSignal("");

export const defaultSearchbarContext = {
    searchbarTerm,
};

export const SearchbarContext = createContext(defaultSearchbarContext);

const searchPaths = [
    "/dictionary/words",
    "/dictionary/kanji",
    "/dictionary/sentences",
    "/dictionary/lessons",
    "/community/users",
];

const getInitialSearchbarTerm = (url: URL) => {
    if (!searchPaths.some((p) => url.pathname.startsWith(p))) return "";
    return url.searchParams.get("q") ?? "";
};

interface Props {
    children: JSX.Element;
    url?: string;
}

export const SearchbarContextProvider: Component<Props> = (props) => {
    const { children, url } = props;

    // const url = useLocation().pathname;
    // const url = "/";
    // console.log(`${mainConfig.resourceBaseURL}${url}`);

    const _searchbarTerm = () => {
        const parsedUrl = new URL(
            url ? `${mainConfig.resourceBaseURL}${url}` : window.location.href
        );
        return getInitialSearchbarTerm(parsedUrl);
    };
    const searchbarTerm = createSignal(_searchbarTerm());

    const value: typeof defaultSearchbarContext = {
        searchbarTerm,
    };

    return (
        <SearchbarContext.Provider value={value}>
            {children}
        </SearchbarContext.Provider>
    );
};

export const useSearchbarCtx = () => useContext(SearchbarContext);
