import SearchbarWithContent from "@client/searchBar/SearchbarWithContent";
import { Navigate } from "@solidjs/router";
import { lazy } from "solid-lazy-plus";

const UserProfileSite = lazy(
    () => import("@client/community/userProfile/UserProfileSite")
);

const UserSearchSite = lazy(
    () => import("@client/community/userSearch/UserSearchSite")
);

// const CommunitySite: Component = () => {
//     return (
//         <Routes>
//             <Route
//                 path="/user/:username/*"
//                 component={() => (
//                     <>
//                         <UserProfileSite />
//                     </>
//                 )}
//             />
//             {/*<AuthRoute path="/community/messages/:messageId" exact={true}*/}
//             {/*    render={(p) =>*/}
//             {/*        <NoSSR>*/}
//             {/*            <MessagesDetailsSiteSolid {...p}/>*/}
//             {/*        </NoSSR>*/}
//             {/*    }*/}
//             {/*/>*/}
//             {/*<AuthRoute path="/community/messages" exact={true}*/}
//             {/*    render={(p) =>*/}
//             {/*        <NoSSR>*/}
//             {/*            <MessagesSiteSolid {...p}/>*/}
//             {/*        </NoSSR>*/}
//             {/*    }*/}
//             {/*/>*/}
//             <Route
//                 path="/"
//                 component={() => (
//                     <>
//                         <Navigate href="/community/users" />
//                     </>
//                 )}
//             />
//             <Route path="*" component={() => <NotFound />} />
//         </Routes>
//     );
// };

export const communitySite = {
    path: "/community",
    // component: lazy(() => import("@client/dictionary/DictionarySite")),
    // component: (props) => {
    //     const { children } = $destructure(props);
    //     return <SearchbarWithContent>{children}</SearchbarWithContent>;
    // },
    children: [
        {
            path: "/user/:username/study-lists",
            component: (props) => {
                return (
                    <Navigate href="/community/user/shiro/study-list/starred" />
                );
            },
        },
        {
            path: "/user/:username/study-list/:idOrName",
            component: (props) => {
                // console.log("hi", props);
                return (
                    <SearchbarWithContent activeSearchSubject="users">
                        <UserProfileSite section="studyLists" />
                    </SearchbarWithContent>
                );
            },
        },
        {
            path: "/user/:username",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="users">
                    <UserProfileSite section="activity" {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/users",
            component: (props) => (
                <SearchbarWithContent activeSearchSubject="users">
                    <UserSearchSite {...props} />
                </SearchbarWithContent>
            ),
        },
        {
            path: "/",
            component: () => <Navigate href="/community/users" />,
        },
    ],
};
