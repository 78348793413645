import Input from "@client/Design/Input/Input.solid";
import Text from "@client/Design/Text";
import Modal from "@client/modal/Modal.solid";
import { css } from "@linaria/core";

const bitcoinAddress = "35sRVikkhnAo2qoVuKjUiGv8UBAh8HFwAJ";
const moneroAddress =
    "89zMKH4xy7AD3CyB2oekKucSNpBkZXhuiP1VPi7GSjZM4CCG7aS7rN8XLAJYieSYXZ2xBkd7ze4kPKx5wzRK9SqNLYCb8eU";
const etheriumAddress = "0xf08404649a4B1F0bC763BC7AadE69b73C23Be789";

interface Props {
    onClose?: () => void;
}

const DonateCryptoModal: Component<Props> = (props) => {
    const { onClose } = $destructure(props);

    return (
        <Modal
            title="Support using crypto"
            onOutsideClick={onClose}
            onClose={onClose}>
            <div class={_DonateCryptoModal}>
                <Text>monero (XMR):</Text>
                <Input value={moneroAddress} />

                <Text>bitcoin (BTC):</Text>
                <Input value={bitcoinAddress} />

                <Text>etherium (ETH):</Text>
                <Input value={etheriumAddress} />
            </div>
        </Modal>
    );
};

const _DonateCryptoModal = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80vw;
    max-width: 500px;
    margin: 16px 0 0;
`;

export default DonateCryptoModal;
