import Link from "@client/Design/Link/Link";
import Text from "@client/Design/Text";
import UnorderedList from "@client/Design/UnorderedList.solid";
import UnorderedListItem from "@client/Design/UnorderedListItem.solid";
import AuthorCard, { Props as IAuthor } from "@client/about/AuthorCard";
import JumbotronHeading from "@client/shared/JumbotronHeading";
import JumbotronSubHeading from "@client/shared/JumbotronSubHeading.solid";
import Title from "@client/shared/Title";
import { breakpoint, contentContainer, text } from "@client/style/commonStyle";
import { discordInviteUrl } from "@client/welcome/DiscordSection.solid";
import { css } from "@linaria/core";

const authors: IAuthor[] = [
    {
        name: "Matic Utsumi Gačar",
        role: "Project lead, Full-stack dev, Designer",
        profilePicturePath:
            "/assets/resources/static/profile-picture-shiro.jpg",
        githubName: "shiro",
        email: "shiro@usagi.io",
        // website: "usagi.io",
        ldGender: "male",
    },
    {
        name: "Maiko Utsumi",
        role: "Content manager, Organizational advisor",
        profilePicturePath:
            "/assets/resources/static/profile-picture-maiko.png",
        email: "maikotobesusume@gmail.com",
        ldGender: "female",
    },
    {
        name: "Andreas Roither",
        role: "Occasional tester",
        profilePicturePath:
            "/assets/resources/static/profile-picture-andreas.jpg",
        githubName: "AndreasRoither",
        email: "andi.roither@protonmail.com",
        ldGender: "male",
    },
];

interface Props {}

const AboutFujiPodSite: Component<Props> = (props) => {
    return (
        <div class={_AboutFujipodSite}>
            <JumbotronHeading>About fujiPod</JumbotronHeading>
            <div>
                <Title>About</Title>

                <section class={Section}>
                    <div class={SectionTextBlock}>
                        <Text component="p">
                            The goal of the fujiPod project is to provide a
                            single platform that makes it easy and fun to study
                            Japanese with interactive content controlled by
                            modern algorithms.
                        </Text>
                        <Text component="p">
                            Everyone is different, so we aim to provide an
                            efficient way of studying for everyone with features
                            like:
                        </Text>
                        <UnorderedList>
                            <UnorderedListItem>
                                a smart dictionary with words, kanji, sentences
                                and lessons
                            </UnorderedListItem>
                            <UnorderedListItem>
                                dynamically generated audio study streams,
                                tailored to your level and vocabulary
                            </UnorderedListItem>
                            <UnorderedListItem>
                                study lists to save and share and organize
                                dictionary entries
                            </UnorderedListItem>
                            <UnorderedListItem>
                                a quiz with spaced repetition and questions
                                dynamically generated for you
                            </UnorderedListItem>
                            <UnorderedListItem>
                                social features to study together with others
                                and encourage each other
                            </UnorderedListItem>
                        </UnorderedList>
                        <Text component="p">
                            We are actively improving features, adding content
                            and thinking about new ways to make studying
                            Japanese fun. Join today and start your journey to
                            the top.
                        </Text>

                        <p>
                            For details about how we handle your data, please
                            check the{" "}
                            <Link to="/privacy-policy">privacy policy</Link>.
                        </p>
                    </div>
                </section>

                <section class={Section}>
                    <JumbotronSubHeading wide>
                        External projects
                    </JumbotronSubHeading>
                    <div class={SectionTextBlock}>
                        <p>
                            We are very thankful for all the awesome projects
                            out there that provided the data and tools to make
                            this project possible.
                        </p>
                        <p>
                            By supporting related projects monetarily, with code
                            contributions and otherwise fujiPod and many other
                            study tools benefit immensely so please check them
                            out if you’re interested.
                        </p>
                        <div class={RelatedProjectList}>
                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project"
                                    target="_blank"
                                    rel="noreferrer">
                                    JMdict
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                Japanese word dictionary database by Jim
                                Breen/EDRDG. It includes meanings in English and
                                various other languages as well as additional
                                information such as part-of-speech
                                classification, additional notes and much more.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://www.edrdg.org/wiki/index.php/KANJIDIC_Project"
                                    target="_blank"
                                    rel="noreferrer">
                                    KANJIDIC2
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                A kanji database by Jim Breen/EDRDG. It includes
                                various information like readings, meanings and
                                references to common dictionaries.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://www.edrdg.org/krad/kradinf.html"
                                    target="_blank"
                                    rel="noreferrer">
                                    RADKFILE/KRADFILE
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                Radical information and reverse lookup by Jim
                                Breen/EDRDG. This adds radical information for
                                kanji.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://kanjivg.tagaini.net/"
                                    target="_blank"
                                    rel="noreferrer">
                                    KanjiVG
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                Kanji stroke order SVG diagrams by Ulrich Apel
                                and other contributors. This allows us to
                                visualize the order of strokes for each kanji.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://taku910.github.io/mecab/"
                                    target="_blank"
                                    rel="noreferrer">
                                    MeCab
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                For parsing japanese sentences and obtaining the
                                part of speech information.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://jisho.org/"
                                    target="_blank"
                                    rel="noreferrer">
                                    Jisho
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                Jisho was a big inspiration when developing the
                                dictionary related parts of the application. It
                                is a powerful Japanese-English dictionary that
                                lets you find words, kanji, example sentences
                                and more quickly and easily.
                            </Text>

                            <h2 class={RelatedProjectName}>
                                <a
                                    href="https://github.com/mifunetoshiro/kanjium"
                                    target="_blank"
                                    rel="noreferrer">
                                    Kanjium
                                </a>
                            </h2>
                            <Text
                                className={RelatedProjectDescription}
                                component="p">
                                Word pitch accent data for many words, displayed
                                on word details pages.
                            </Text>
                        </div>
                    </div>
                </section>

                <section class={Section}>
                    <JumbotronSubHeading wide>Contact</JumbotronSubHeading>
                    <div class={SectionTextBlock}>
                        Join our{" "}
                        <Link to={discordInviteUrl}>Discord channel</Link> or
                        send an email to{" "}
                        <Link to="mailto:support@fujipod.com">
                            support@fujipod.com
                        </Link>{" "}
                        if you have any questions, suggestions or if there’s
                        anything else you want us to know.
                    </div>
                    <div class={SectionTextBlock}>
                        Feedback helps us improve and add new features, so join
                        the community and join us on the journey to the top!
                    </div>
                </section>

                <section class={Section}>
                    <JumbotronSubHeading wide>Authors</JumbotronSubHeading>
                    <ul class={AuthorList}>
                        {authors.map((author) => (
                            <li>
                                <AuthorCard {...author} />
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
    );
};

const _AboutFujipodSite = css`
    ${contentContainer}
    margin-bottom: 32px;
`;

const Section = css`
    margin: 16px 0 0;
    &:first-child {
        margin-top: 0;
    }
`;

// const Heading = styled.h1`
//   ${text("primaryBold", "heading1", "colors/text-600a")}
// `;

const SectionTextBlock = css`
    ${text("primary", "body", "colors/text-600a")}
`;

const RelatedProjectList = css`
    display: grid;
    grid-template-columns: auto 1fr;

    grid-row-gap: 8px;
    margin: 16px 0 0;
    ${breakpoint("xs")} {
        grid-template-columns: auto;
    }
`;

const RelatedProjectName = css`
    margin: 0;

    ${text("primaryBold", "body", "link-primary-600")}
    margin: 0 16px 0 0;
`;

const RelatedProjectDescription = css`
    ${text("primary", "body", "colors/text-600a")}
    margin: 0;
`;

const AuthorList = css`
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-column-gap: 32px;
    grid-row-gap: 32px;
`;

// const AuthorsListItem = styled.li``;

export default AboutFujiPodSite;
