import {
    AppContext,
    defaultAppContext,
    Theme,
} from "@client/context/AppContext";
import { SSRContext } from "@client/context/SSRContext";
import { createLazySignal } from "@core/util/solid/createLazySignal";
import { getCookie, setCookie } from "@core/util/typescript/cookieUtil";
import { authenticateUser } from "@server/util/authenticateUser";
import { cache, createAsync } from "@solidjs/router";
import { Component, JSX, useContext } from "solid-js";
import { getRequestEvent, isServer } from "solid-js/web";
import { parseCookies } from "vinxi/http";

// // for now always default to dark theme
// const browserTheme: Theme =
//     (!IS_SERVER &&
//         window.matchMedia &&
//         window?.matchMedia('(prefers-color-scheme: light)').matches) ||
//     getCookie("theme") == "light" ?
//         "light" : "dark";
//
// const initialTheme: Theme = localStorage.getItem("theme") as Theme ||
//     getCookie("theme") as Theme ||
//     browserTheme;
//
// // make sure the cookie is set to make SSR look nicer
// if (!getCookie("theme")) {
//     setCookie("theme", initialTheme, 1000 * 60 * 60 * 24 * 1000);
// }
//
const applyThemeToDocument = (value: Theme) =>
    (document.documentElement.className = `theme-${value}`);
// applyThemeToDocument(initialTheme);

const updateThemeCookie = (theme: Theme) => {
    if (isServer) return;
    if (getCookie("theme") == theme) return;
    setCookie("theme", theme, 1000 * 60 * 60 * 24 * 1000);
    localStorage.setItem("theme", theme);
};

const authFn = cache(async () => {
    "use server";
    const event = getRequestEvent()!;
    const cookies = parseCookies(event);
    return authenticateUser({ cookies });
}, "user");

interface Props {
    children?: JSX.Element;
    cookies?: Record<string, any>;
}

const AppContextProvider: Component<Props> = (props) => {
    const { children } = $destructure(props);

    const auth = createAsync(authFn);

    const { theme: ssrTheme } = useContext(SSRContext);
    let theme = $signal(ssrTheme);

    updateThemeCookie(theme);

    const setTheme: Setter<Theme> = (value) => {
        if (typeof value == "function") value = value(theme);
        theme = value;
        updateThemeCookie(theme);
        applyThemeToDocument(theme);
        return value;
    };

    // const pendingMessages: any = createServerSignal((async () => {}) as any);

    const loggedInUser = createLazySignal(() => auth()?.user);
    const [timezone] = createLazySignal(() => auth()?.timezone);

    $effect(() => {
        const jwt = auth()?.jwt;
        if (jwt === undefined) return;
        setCookie("accessToken", jwt ?? undefined);
    });

    // const online = $signal<boolean>(true);

    return (
        <AppContext.Provider
            value={{
                ...defaultAppContext,
                theme: [$get(theme), setTheme],
                get timezone() {
                    return loggedInUser ? timezone() : undefined;
                },
                // pendingMessages,
                loggedInUser,
                // online: $refSignal(online),
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
