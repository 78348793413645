// @refresh reload
import "@client/style/global.style";

import App from "@client/app/App";
import AppContextProvider from "@client/context/AppContextProvider";
import { routes } from "@client/routes";
import { trpc } from "@client/trpc/trpcClientUtil";
import { makeQueryClient } from "@server/trpc/trpcCreateClient";
import { MetaProvider } from "@solidjs/meta";
import { Router } from "@solidjs/router";
import { QueryClientProvider, isServer } from "@tanstack/solid-query";
import SerializeSSRValuesScript from "./app/SerializeSSRValuesScript";
import { lazy } from "solid-lazy-plus";

const Preload = lazy(() => import("./preload"));

const MyApp = (props: any) => (
    <AppContextProvider>
        <App {...props} />
    </AppContextProvider>
);

const Entrypoint = () => {
    // if (isServer) return null;
    const queryClient = makeQueryClient();

    return (
        <MetaProvider>
            <QueryClientProvider client={queryClient}>
                <trpc.Provider queryClient={queryClient}>
                    <SerializeSSRValuesScript />
                    <Suspense>
                        {/* <Preload /> */}
                        <Router preload={false} root={MyApp}>
                            {routes}
                        </Router>
                    </Suspense>
                </trpc.Provider>
            </QueryClientProvider>
        </MetaProvider>
    );
};

export default Entrypoint;
