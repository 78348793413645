import SVG from "@client/shared/SVG";
import { color } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props {
    className?: string;
}

const GooglePlayLogo: Component<Props> = (props) => {
    const { className } = $destructure(props);

    return (
        <SVG
            className={cn(Logo, className)}
            src={`/assets/resources/static/misc/google-play-logo.svg`}
        />
    );
};

const Logo = css`
    .shape1 {
        fill: ${color("colors/primary-400")} !important;
    }
    .shape2 {
        fill: ${color("colors/primary-600")} !important;
    }
    .shape3 {
        fill: ${color("colors/primary-500")} !important;
    }
    .shape4 {
        fill: ${color("colors/primary-200")} !important;
    }
    .shape5 {
        fill: rgba(0, 0, 0, 0.2) !important;
    }
    .shape6 {
        fill: rgba(0, 0, 0, 0.12) !important;
    }
    .shape7 {
        fill: rgba(0, 0, 0, 0.25) !important;
    }
`;

export default GooglePlayLogo;
