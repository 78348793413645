import {registerPlugin} from "@capacitor/core";
import {IS_NATIVE} from "@client/native/platform";


export interface CapacitorConfigPlugin {
    get(): Promise<{
        PUBLIC_HOST: string;
        PUBLIC_PORT: string;
        HTTPS_ENABLED: boolean;
        LOG_LEVEL: boolean;
    }>;
}

export const capacitorConfigPlugin = IS_NATIVE ?
    registerPlugin<CapacitorConfigPlugin>("config") :
    undefined;