import { Component, JSX } from "solid-js";
import { isServer } from "solid-js/web";

interface Props {
    children?: JSX.Element;
}

const NoSSR: Component<Props> = (props) => {
    if (isServer) return null;
    let mounted = $signal(false);

    $mount(() => {
        mounted = true;
    });

    return <Show when={mounted}>{props.children}</Show>;
};

export default NoSSR;
