import type { StreamInfo } from "@server/podcast/AgnosticStreamClient";
import type { FeedUpdateEvent } from "@server/podcast/podcastMessaging";
import { createContext, createSignal, useContext } from "solid-js";

export const defaultStreamContext = {
    playStream: null as
        | null
        | ((name: string, parameters?: Record<string, any>) => Promise<void>),
    currentStreamInfo: createSignal<StreamInfo | undefined>(),
    currentFeedMessage: createSignal<FeedUpdateEvent | undefined>(),
};

export const StreamContext = createContext(defaultStreamContext);

export const useStreamCtx = () => useContext(StreamContext);
