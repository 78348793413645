import IconText from "@client/Design/IconText.solid";
import LinkButton from "@client/Design/Link/LinkButton.solid";
import Text from "@client/Design/Text";
import { useAppCtx } from "@client/context/AppContext";
import JumbotronSubHeading from "@client/shared/JumbotronSubHeading.solid";
import {
    breakpointUntil,
    contentContainer,
    heading3Text,
} from "@client/style/commonStyle";
import { css } from "@linaria/core";
import { Component } from "solid-js";

export const discordInviteUrl = "https://discord.gg/wugwPzek2B";

interface Props {}

const DiscordSection: Component<Props> = (props) => {
    const appCtx = useAppCtx();
    const theme = $memo(appCtx.theme[0]());

    return (
        <>
            <JumbotronSubHeading>Join the chat</JumbotronSubHeading>
            <div class={Container}>
                <img
                    class={Drawing}
                    src={`/assets/resources/static/misc/chat-bubbles.${theme}.png`}
                />

                <div class={Side}>
                    <Text component="p">
                        Get the latest news, feature previews and support in
                        our&nbsp;
                        <Text className="text-colors-secondary-800">
                            Discord chat
                        </Text>
                        . If you want to report bugs, have a question or
                        suggestion, or just want to talk join the Discord
                        server, it&apos;s free and open for everyone.
                    </Text>

                    <LinkButton
                        className={DiscordButton}
                        to={discordInviteUrl}
                        variant="primaryHollow"
                        plain
                        target="_blank">
                        <IconText icon="discord" />
                        <Text>Discord chat</Text>
                    </LinkButton>
                </div>
            </div>
        </>
    );
};

const Container = css`
    ${contentContainer}
    display: flex;
    gap: 32px;
    ${breakpointUntil("s")} {
        flex-direction: column;
    }
`;

const Side = css`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const drawingScale = 280 / 500;
const drawingWidth = 250;

const Drawing = css`
    flex: 1;
    width: ${drawingWidth}px;
    height: ${drawingWidth * drawingScale}px;
    margin: 0 16px;
    align-self: center;
`;

const DiscordButton = css`
    align-self: flex-start;
    gap: 4px;
    ${heading3Text}
    ${breakpointUntil("s")} {
        align-self: center;
    }
`;

export default DiscordSection;
