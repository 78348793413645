import { useAppCtx } from "@client/context/AppContext";
import NoSSR from "@client/shared/NoSSR";
import { UserRole } from "@core/sharedEnums";
import { userHasRole } from "@server/auth/userRoles";
import { Navigate } from "@solidjs/router";
import { JSX, Match, Switch } from "solid-js";

type Props = {
    type?: "usersOnly" | "guestOnly";
    role?: UserRole;
    children: JSX.Element;
};

const AuthRoute = (props: Props) => {
    const { type = "usersOnly", role, children } = $destructure(props);
    const ctx = useAppCtx();
    const user = $derefSignal(ctx.loggedInUser)!;

    return (
        <Switch>
            <Match when={type == "guestOnly" && user != null}>
                <Navigate href="/" />
            </Match>
            <Match
                when={
                    type == "usersOnly" &&
                    (user == null || (role && !userHasRole(user, role)))
                }>
                <NoSSR>
                    <Navigate href="/welcome" />
                </NoSSR>
            </Match>
            <Match when={true}>{children}</Match>
        </Switch>
    );
};

export default AuthRoute;
