import RouterLink from "@client/Design/Link/RouterLink.solid";
import { useAppCtx } from "@client/context/AppContext";
import { IS_NATIVE } from "@client/native/platform";
import SignupRequiredModal from "@client/shared/SignupRequiredModal.solid";
import {
    bodyText,
    breakpoint,
    breakpointUntil,
    color,
    heading3Text,
    withStyle,
} from "@client/style/commonStyle";
import { UserRole } from "@core/sharedEnums";
import { css } from "@linaria/core";
import { userHasRole } from "@server/auth/userRoles";
import type { UserAuthSchema } from "@server/entities/UserEnt";
import { useLocation } from "@solidjs/router";
import cn from "classnames";
import { Component, For, Show, Suspense } from "solid-js";

type Navigation = {
    label: string;
    to: string;
    cyLabel: string;
    isActive: ({ pathname }: { pathname: string }) => boolean;
    requiresSignup?: boolean;
    // hidden?(user: undefined | UserAuthSchema): boolean;
    subNavigation: {
        label: string;
        to: string;
        icon?: string;
        isActive: ({ pathname }: { pathname: string }) => boolean;
        requiresSignup?: boolean;
        hidden?(user: undefined | UserAuthSchema): boolean;
    }[];
}[];

export const navigation: Navigation = [
    {
        label: "dictionary",
        to: "/",
        cyLabel: "dictionary-link",
        isActive: ({ pathname }) =>
            pathname == "/" ||
            pathname.startsWith("/dictionary") ||
            pathname.startsWith("/welcome"),
        subNavigation: [
            {
                label: "dashboard",
                to: "/",
                icon: "house",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname == "/",
            },
            {
                label: "words",
                to: "/dictionary/words",
                icon: "hira-a",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/dictionary/word"),
            },
            {
                label: "kanji",
                to: "/dictionary/kanji",
                icon: "kanji",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/dictionary/kanji"),
            },
            {
                label: "sentences",
                to: "/dictionary/sentences",
                icon: "message",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/dictionary/sentence"),
            },
            {
                label: "lessons",
                to: "/dictionary/lesson/overview",
                icon: "book",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/dictionary/lesson"),
            },
        ],
    },
    {
        label: "study",
        to: "/study",
        cyLabel: "study-link",
        isActive: ({ pathname }) => pathname.startsWith("/study"),
        requiresSignup: true,
        subNavigation: [
            {
                label: "streams",
                to: "/study/streams",
                icon: "note",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/study/streams"),
                requiresSignup: true,
            },
            {
                label: "quiz",
                to: "/study/quiz",
                icon: "clock",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/study/quiz"),
                requiresSignup: true,
            },
            {
                label: "recordings",
                to: "/study/recordings",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/study/recordings"),
                hidden: (user) => !userHasRole(user, UserRole.Admin),
            },
        ],
    },
    {
        label: "community",
        to: "/community",
        cyLabel: "community-link",
        isActive: ({ pathname }) =>
            pathname.startsWith("/community") ||
            pathname.startsWith("/password-reset") ||
            pathname.startsWith("/signup"),
        subNavigation: [
            {
                label: "users",
                to: "/community",
                icon: "user",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/community/users"),
            },
        ],
    },
    {
        label: "about",
        to: "/about/fujipod",
        cyLabel: "about-link",
        isActive: ({ pathname }) => pathname.startsWith("/about"),
        subNavigation: [
            {
                label: "fujiPod",
                to: "/about/fujipod",
                icon: "fuji",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname == "/about/fujipod",
            },
            {
                label: "donate",
                to: "/about/donate",
                icon: "heart",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/about/donate"),
            },
            {
                label: "apps",
                to: "/about/apps",
                icon: "box",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/about/apps"),
                hidden: () => IS_NATIVE,
            },
            {
                label: "FAQ",
                to: "/about/faq",
                icon: "question",
                isActive: ({ pathname }: { pathname: string }) =>
                    pathname.startsWith("/about/faq"),
            },
        ],
    },
];

interface Props {
    className?: string;
    onLinkClick?: (ev) => void;
}

const NavBar: Component<Props> = (props) => {
    const { className, onLinkClick } = $destructure(props);
    const ctx = useAppCtx();
    const user = $derefSignal(ctx.loggedInUser);

    let modalOpen = $signal(false);

    const pathname = $memo(useLocation().pathname);

    const subNavigation = $memo(
        navigation.find((n) => n.isActive({ pathname }))?.subNavigation
    );

    const handleSignupRequired = (ev) => {
        ev.preventDefault();
        modalOpen = true;
        onLinkClick?.(ev);
    };

    return (
        <div class={cn(Nav, className)}>
            <div class={NavRow}>
                <For each={navigation}>
                    {(n) => (
                        <RouterLink
                            to={n.to}
                            className={cn(NavLink, {
                                active: n.isActive({ pathname }),
                            })}
                            data-cy={`header-${n.cyLabel}`}
                            onClick={
                                n.requiresSignup && !user
                                    ? handleSignupRequired
                                    : onLinkClick
                            }>
                            {n.label}
                        </RouterLink>
                    )}
                </For>
            </div>
            <Show when={subNavigation}>
                <Suspense>
                    <div class={NavRow}>
                        <For each={subNavigation}>
                            {(n) => (
                                <Show when={!n.hidden?.(user)}>
                                    <RouterLink
                                        to={n.to}
                                        className={cn(NavLink, "secondary", {
                                            active: n.isActive({ pathname }),
                                        })}
                                        onClick={
                                            n.requiresSignup && !user
                                                ? handleSignupRequired
                                                : onLinkClick
                                        }>
                                        {n.label}
                                    </RouterLink>
                                </Show>
                            )}
                        </For>
                    </div>
                </Suspense>
            </Show>
            <Show when={modalOpen}>
                <SignupRequiredModal
                    onClose={() => {
                        modalOpen = false;
                    }}
                />
            </Show>
        </div>
    );
};

const Nav = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    margin-right: auto;
    margin-bottom: auto;

    ${breakpointUntil("s")} {
        display: none;
    }
`;

const NavRow = css`
    display: flex;
    gap: 16px;
`;

const NavLink = css`
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;

    &,
    &:visited,
    &:hover,
    &:active {
        color: ${color("colors/text-600a")};

        ${heading3Text}
        &.secondary {
            color: ${color("colors/text-300a")};
            ${bodyText}
        }
    }

    ${breakpoint("xs")} {
        margin-left: 16px;
    }

    &.active {
        color: ${color("colors/primary-800")} !important;
    }

    &:hover {
        color: ${color("colors/primary-800")} !important;
        text-decoration: none;
    }
`;

export default withStyle(NavBar, { Container: Nav });
