import RouterLink from "@client/Design/Link/RouterLink.solid";
import { useAppCtx } from "@client/context/AppContext";
import { SearchbarContext } from "@client/context/SearchbarContext";
import HeaderUser from "@client/header/HeaderUser.solid";
import MobileHeaderContent from "@client/header/MobileHeaderContent.solid";
import NavBar from "@client/header/NavBar";
import ThemeToggle from "@client/header/ThemeToggle.solid";
import SVG from "@client/shared/SVG";
import { logoStyle } from "@client/sharedStyles/logoStyle";
import {
    breakpointFrom,
    breakpointUntil,
    color,
    contentContainer,
    dropShadow,
    heading1Text,
    style,
} from "@client/style/commonStyle";
import { useOnClickOutside } from "@core/util/solid/useOnClickOutside.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, useContext } from "solid-js";

const searchPathnames = [
    new RegExp("^/$"),
    new RegExp("^/dashboard$"),
    new RegExp("^/dictionary/words$"),
    new RegExp("^/dictionary/word/[^/]+$"),
    new RegExp("^/dictionary/kanji$"),
    new RegExp("^/dictionary/kanji/[^/]+$"),
    new RegExp("^/dictionary/sentences$"),
    new RegExp("^/dictionary/sentence/[^/]+$"),
    new RegExp("^/dictionary/lessons$"),
    new RegExp("^/dictionary/lesson/[^/]+$"),
    new RegExp("^/community/users$"),
];

interface Props {}

const Header: Component<Props> = (props) => {
    let searchbarFocused = $signal(false);
    const atTop = $signal(true);
    let containerRef!: HTMLDivElement;

    // const location = useLocation();
    const appCtx = useAppCtx();
    const scrollLocked = $derefSignal(appCtx.scrollLocked);

    const searchbarCtx = useContext(SearchbarContext);

    const clearSearchBar = () => {
        searchbarCtx.searchbarTerm[1]("");
    };

    // useWindowScroll(() => {
    //     if (scrollLocked) return;
    //     if (!atTop && window.scrollY == 0) atTop = true;
    //     else if (atTop && window.scrollY != 0) atTop = false;
    // });

    $mount(() => {
        useOnClickOutside(
            () => [containerRef],
            () => {
                searchbarFocused = false;
            },
            () => searchbarFocused
        );
    });

    return (
        <div
            ref={containerRef}
            class={cn(_HeaderWrapper, { focused: searchbarFocused })}>
            <div class={cn(_Header, { atTop })}>
                <MobileHeaderContent
                    searchbarFocused={searchbarFocused}
                    onSearchbarFocusChange={(focus) => {
                        searchbarFocused = focus;
                    }}
                />

                <RouterLink
                    className={LogoNavLink}
                    to="/"
                    aria-label="main page link"
                    onClick={clearSearchBar}>
                    <SVG
                        src="/assets/resources/static/logo.svg"
                        className={headerLogoStyle}
                    />
                </RouterLink>

                <NavBar
                    className={navBarStyle}
                    // onLinkClick={clearSearchBar}
                />

                {/*<PlainButton*/}
                {/*    onClick={() => {searchbarFocused = !searchbarFocused}}*/}
                {/*    className={cn(SearchButton, {hidden: searchPathnames.some(p => p.test(location().pathname))})}>*/}
                {/*    <Icon className={SearchButtonIcon} icon={searchbarFocused ? "cross" : "search"}/>*/}
                {/*</PlainButton>*/}

                <ThemeToggle className={StyledThemeToggle} />

                <HeaderUser hidden={searchbarFocused} />
            </div>

            {/*<HeaderSearchbar*/}
            {/*    visible={searchbarFocused}*/}
            {/*    onClose={() => {searchbarFocused = false}}*/}
            {/*/>*/}
        </div>
    );
};

const _HeaderWrapper = css`
    display: flex;
    background: ${color("colors/special-bg")};
    &.focused {
        z-index: 10200;
    }
    ${breakpointUntil("s")} {
        display: block;
        height: 84px;
    }
`;

const slideDownAnimation = style`
  @keyframes slideDown {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
  }
`;

const _Header = css`
    display: flex;
    align-items: center;
    height: 60px;
    ${breakpointFrom("s")} {
        ${contentContainer}
        margin-top: 16px;
        margin-bottom: 16px;
    }
    ${breakpointUntil("s")} {
        position: fixed;
        top: 0;
        height: 48px;
        box-sizing: content-box;
        padding: 16px 32px;
        gap: 32px;
        z-index: 400;
        width: calc(100% - 64px);
        background: ${color("colors/special-bg")};
        ${dropShadow(0, 1, 10, "rgba(0, 0, 0, 0.3)")}
        animation: slideDown 0.5s;
        &.atTop {
            filter: none;
        }
    }
    ${slideDownAnimation}
`;

const navBarStyle = css`
    margin-left: 32px;
`;

const headerLogoStyle = css`
    width: 63px;
    height: 58px;
    ${logoStyle}
`;

const LogoNavLink = css`
    display: flex;
    width: 65px;
    height: 60px;
    align-items: center;
    justify-content: center;
    ${breakpointUntil("s")} {
        display: none;
    }
`;

const StyledThemeToggle = css`
    margin-left: 16px;
    ${breakpointUntil("s")} {
        display: none;
    }
`;

const SearchButton = css`
    height: 40px;
    margin-left: 16px;
    ${heading1Text}
    ${breakpointUntil("s")} {
        display: none;
    }
    &.hidden {
        display: none;
    }
`;

const SearchButtonIcon = css`
    width: 40px;
    height: 40px;
`;

export default Header;
