import { trpcConfigStart } from "@server/trpc/trpcConfig";
import type { AppRouter } from "@server/trpc/trpcRouter";
import type { inferProcedureOutput } from "@trpc/server";
// import { createTRPCSolidStart } from "solid-trpc";
// import { createTRPCSolid } from "solid-trpc";
import { createTRPCSolidStart } from "@solid-mediakit/trpc";

export type APIReturn<
    T extends keyof AppRouter,
    T2 extends keyof AppRouter[T],
> = inferProcedureOutput<AppRouter[T][T2]>;

// export const trpc = createTRPCSolid<AppRouter>();
export const trpc = createTRPCSolidStart<AppRouter>({
    config(event) {
        // PageEvent of Solid-start
        return trpcConfigStart();
    },
});
// export const trpcClient = trpc.createClient(trpcConfigStart());

// const _trpc = createTRPCReact<AppRouter>();
// export const trpcSolid = createTRPCSolid<AppRouter>();
//
//
// // trpc react
// const customTrpcUseContext = () => {
//     const qc = useQueryClient();
//
//     const p = (path: string[]) => new Proxy({
//         invalidate: async () => {
//             await qc.invalidateQueries({
//                 queryKey: [path],
//             });
//         }
//     }, {
//         get: (self, key: string) => {
//             if (!path.length &&
//                 ["client", "ssrState", "ssrContext", "abortOnUnmount"].includes(key)) {
//                 return _trpc.useContext()[key];
//             }
//
//             if (key == "invalidate") {
//                 return self.invalidate;
//             }
//
//             return p([...path, key]);
//         }
//     });
//
//
//     return p([]) as ReturnType<typeof trpc["useContext"]>;
// };
//
//
// // export const trpc = new Proxy(_trpc, {
// //     get: (trpc, key) => {
// //         if (key == "useContext") return customTrpcUseContext;
// //         return trpc[key];
// //     }
// // });
//
//
// // trpc solid
// // the server is not supposed to run solid trpc SSR, but we do anyway
// // since we get the data wrapped in accessors, we need to unwrap it
// const mapSolidServerTrpc = <V extends Record<string, any>>(v: V): V => {
//     if (!IS_SERVER) return v;
//     return {...v, data: v.data()};
// };
//
// const customTrpcSolid = (firstPart: string) => {
//     const p = (path: string[]) => new Proxy({}, {
//         get: (_, key: string) => {
//             const finalPart = path.reduce((acc, key) => acc[key], _trpcSolid)
//
//             if (["useMutation"].includes(key)) {
//                 return finalPart[key];
//             }
//
//             // custom query mapping to handle the inconsistent response type on the server
//             if (key == "useQuery") {
//                 return (...args) => mapSolidServerTrpc(finalPart.createQuery(...args));
//             }
//
//             return p([...path, key]);
//         }
//     });
//
//     return p([firstPart]);
// };
//
// export const trpcSolid = new Proxy(_trpcSolid, {
//     get: (trpc, key: string) => {
//         if (["useContext", "Provider", "createClient", "useDehydratedState"].includes(key)) {
//             return trpc[key];
//         }
//         return customTrpcSolid(key);
//     },
// });
