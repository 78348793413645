import { css } from "@linaria/core";
import { Component } from "solid-js";

export const portalContainerId = "portalContainer";

const PortalContainer: Component = () => {
    return <div class={container} id={portalContainerId} />;
};

const container = css`
    // & > *:not(:first-child) {
    //   visibility: hidden;
    // }
`;

export default PortalContainer;
