/** @jsxRuntime automatic */
/** @jsxImportSource solid-js */
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import cn from "classnames";
import { Component, ComponentProps } from "solid-js";

interface Props extends AttributeStyleProps, ComponentProps<"li"> {
    className?: string;
}

const UnorderedListItem: Component<Props> = (props) => {
    const { children, className, ...other } = $destructure(props);
    const { style, classes, rest } = useAttributeStyle(other);

    return (
        <li class={cn(classes, className)} style={style} {...rest}>
            {children}
        </li>
    );
};

export default UnorderedListItem;
