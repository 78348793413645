import { InputInput } from "@client/Design/Input/Input.style";
import { withStyle } from "@client/style/commonStyle";
import { HTMLProps } from "@core/util/solid/HTMLProps.solid";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import cn from "classnames";
import { Component } from "solid-js";

interface Props
    extends AttributeStyleProps,
        Omit<HTMLProps<"input">, keyof AttributeStyleProps> {
    ref?: (ref: HTMLInputElement) => void;
    className?: string;
    focusOnMount?: boolean;
    variant?: "primary" | "underline";
    initialValue?: string | number;
}

const Input: Component<Props> = (props) => {
    const {
        ref: _ref,
        className,
        value,
        initialValue,
        type = "text",
        variant = "primary",
        focusOnMount,
        onInput,
        ...other
    } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    let ref!: HTMLInputElement;

    $mount(() => {
        if (value == "") ref.value = "";
        if (!focusOnMount) return;
        ref.focus();
    });

    const handleInput = (ev) => {
        if (typeof onInput == "function") {
            onInput(ev);
        }

        if (value == undefined) return;
        ev.currentTarget.value = value;
    };

    return (
        <input
            ref={(r) => {
                ref = r;
                _ref?.(r);
            }}
            class={cn(InputInput, classes, className, variant)}
            style={style}
            type={type}
            value={
                value != null ? value : initialValue != null ? initialValue : ""
            }
            onInput={handleInput}
            {...rest}
        />
    );
};

export default withStyle(Input, { Container: InputInput });
