import {isServer} from "solid-js/web";

// export const getCookies = () => {
//     if (isServer)
//         throw new Error("'getCookies' cannot be called on the server");
//
//     return Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));
// }

export const getCookie = (name: string) => {
    if (isServer)
        throw new Error("'getCookie' cannot be called on the server");

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length == 2)
        return parts.pop()?.split(';').shift();
};

// export const useCookie = (name: string) => {
//     if (!isServer)
//         return getCookie(name);
//
//
//     const ctx = useContext(AppContext);
//     return ctx.cookies?.[name];
// }


export const setCookie = (name: string, value?: string, maxAge?: number) => {
    if (value == undefined) {
        value = "";
        maxAge = -1;
    }

    let expires = "; expires=" + Number.MAX_VALUE;
    if (maxAge != null) {
        expires = "; max-age=" + maxAge;
    }
    document.cookie = name + "=" + value + expires + "; path=/";
    return value;
};

// export const useCookieSolid = (name: string) => {
//     if (!IS_SERVER)
//         return createMemo(() => getCookie(name));
//
//
//     const ctx = useReactContext(AppContext);
//     return createMemo(() => ctx().cookies?.[name]);
// }