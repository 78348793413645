import Icon from "@client/Design/Icon.solid";
import { withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, ComponentProps, JSX } from "solid-js";

interface Props extends AttributeStyleProps, ComponentProps<"span"> {
    // TODO add all sizes
    size?: "small" | "sub" | "body" | "heading1" | "heading2" | "heading3";
    className?: string;
    icon?: string;
    description?: string;
    children?: JSX.Element;
    onclick?: () => void;
}

const IconText: Component<Props> = (props) => {
    const { className, children, icon, description, ...other } =
        $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <span class={cn(_IconText, classes, className)} style={style} {...rest}>
            {icon ? <Icon icon={icon} description={description} /> : children}
        </span>
    );
};

const iconRatio = 0.63;
const marginTopRatio = 0.2;
const marginBottomRatio = 1 - iconRatio - marginTopRatio;

const _IconText = css`
    display: inline-flex;
    justify-content: center;
    margin: calc(${marginTopRatio} * var(--line-height)) 0
        calc(${marginBottomRatio} * var(--line-height));
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    & > * {
        width: calc(${iconRatio} * var(--line-height));
        height: calc(${iconRatio} * var(--line-height));
    }
`;

export default withStyle(IconText, { Container: _IconText });
