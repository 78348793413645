import { useAppCtx } from "@client/context/AppContext";
import {
    seekbarHeight,
    seekbarHeightM,
    seekbarHeightS,
} from "@client/seekbar/Seekbar";
import { breakpointUntil } from "@client/style/commonStyle";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component } from "solid-js";

interface Props {}

const AppSpacer: Component<Props> = (props) => {
    const appCtx = useAppCtx();
    const seekbarVisible = $derefSignal(appCtx.seekbarVisible);

    return <div class={cn(Spacer, { seekbarVisible })} />;
};

const Spacer = css`
    height: 64px;
    ${breakpointUntil("m")} {
        height: 16px;
        &.seekbarVisible {
            height: ${seekbarHeight + 16}px;
            ${breakpointUntil("m")} {
                height: ${seekbarHeightM + 16}px;
            }
            ${breakpointUntil("s")} {
                height: ${seekbarHeightS + 16}px;
            }
        }
    }
`;

export default AppSpacer;
