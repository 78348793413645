import { SSRContext, defaultSSRContext } from "@client/context/SSRContext";
import { Component, JSX } from "solid-js";

interface Props {
    children?: JSX.Element;
}

const SSRContextProvider: Component<Props> = (props) => {
    const { children } = $destructure(props);

    // @ts-ignore
    const hydratedValue = window.__SSR_CTX ?? {};

    return (
        <SSRContext.Provider
            value={{
                ...defaultSSRContext,
                ...hydratedValue,
            }}>
            {children}
        </SSRContext.Provider>
    );
};

export default SSRContextProvider;
