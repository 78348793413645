import Text from "@client/Design/Text";
import { FontSize } from "@client/style/commonStyle";
import cn from "classnames";
import { Component, ComponentProps, JSX } from "solid-js";

interface Props extends ComponentProps<typeof Text> {
    children?: JSX.Element;
    level?: 1 | 2 | 3 | 4 | 5;
    centered?: boolean;
}

const Heading: Component<Props> = (props) => {
    const {
        children,
        level = 1,
        centered,
        className,
        ...rest
    } = $destructure(props);

    const component = `h${level}`;
    const fontSize = `text-heading${level <= 3 ? level : 3}` as FontSize;

    return (
        <Text
            component={component}
            className={cn(fontSize, className)}
            {...rest}>
            {children}
        </Text>
    );
};

export default Heading;
