import AppDrawer from "@client/Design/AppDrawer.solid";
import { sizeSWidth } from "@client/style/sizesTS";
import { useWindowSize } from "@core/util/solid/useWindowSize.solid";
import { Component, ComponentProps } from "solid-js";

interface Props extends ComponentProps<typeof AppDrawer> {}

const LazyAppDrawer: Component<Props> = (props) => {
    let loaded = $signal(false);
    let component = $signal<Component | undefined>();
    const { windowSize } = useWindowSize({
        debounceTimeout: 2000,
    });

    $effect(async () => {
        const small = windowSize()[0] < sizeSWidth;
        if (!loaded && small) {
            loaded = true;
            if (!$untrack(component)) {
                const m = await import("@client/Design/AppDrawer.solid");
                component = m.default;
            }
        } else if (loaded && !small) {
            loaded = false;
        }
    });

    return (
        <Show when={loaded}>
            <Dynamic component={component} {...props} />
        </Show>
    );
};

export default LazyAppDrawer;
