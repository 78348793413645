import { AppContext, useAppCtx } from "@client/context/AppContext";
import { HTMLProps } from "@core/util/solid/HTMLProps.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX, useContext } from "solid-js";

export const localStorageCachedProfilePictureUrlKey = (username: string) =>
    `${username}-profilePictureUrl`;

interface Props extends Omit<HTMLProps<"img">, "src" | "class"> {
    ref?: (r: HTMLImageElement) => void;
    children?: JSX.Element;
    className?: string;
}

const CurrentUserProfilePicture: Component<Props> = (props) => {
    const { ref, children, className, ...rest } = $destructure(props);
    const appCtx = useAppCtx();
    const user = $derefSignal(appCtx.loggedInUser);

    const theme = $derefSignal(useContext(AppContext).theme);
    // const url = $derefMemo(createMemo(() => {
    //     const placeholderUrl = `/assets/resources/static/misc/guest-user.${theme}.png`;
    //     if (!user) return placeholderUrl;
    //     return user.profilePictureUrl;
    // }));

    // let queryEnabled = $signal(false);
    //
    // const query = trpc.user.userDetails.createQuery(
    //     () => ({id: user?.id}),
    //     () => ({
    //         enabled: queryEnabled,
    //         onSuccess: (data) => {
    //             if (!user || !data.profilePictureUrl || !localStorage) return;
    //             localStorage.setItem(localStorageCachedProfilePictureUrlKey(user.username), data.profilePictureUrl);
    //         }
    //     }),
    // );
    //
    // const placeholderUrl = $memo(`/assets/resources/static/misc/guest-user.${theme}.png`);
    //
    // const url = $derefMemo(createMemo(() => {
    //     if (!user) return placeholderUrl;
    //
    //     if (!isServer) {
    //         const cached = localStorage.getItem(localStorageCachedProfilePictureUrlKey(user.username));
    //         if (cached) return cached;
    //     }
    //
    //     if (!queryEnabled) {
    //         queryEnabled = true;
    //     }
    //
    //     return query.data?.profilePictureUrl ?? user?.profilePictureUrl ?? placeholderUrl;
    // }));
    // console.log("pics",url);

    return (
        <img
            ref={ref}
            src={
                user?.profilePictureUrl ??
                `/assets/resources/static/misc/guest-user.${theme}.png`
            }
            class={cn(ProfilePicture, { guest: !user }, className)}
            // alt="User profile picture"
            {...rest}
        />
    );
};

const ProfilePicture = css`
    border-radius: 8px;

    &.guest {
        padding: 8px;
    }
`;

export default CurrentUserProfilePicture;
