export const useOnClickOutside = (
    refs: () => (Element | undefined)[],
    handler?: (ev: MouseEvent | TouchEvent) => void,
    active?: () => boolean
) => {
    const listener = (ev: (MouseEvent | TouchEvent) & { target: any }) => {
        // Do nothing if clicking ref's element or descendent elements
        for (const ref of refs()) {
            if (ref?.contains(ev.target)) return;
        }

        handler?.(ev);
    };

    $effect(() => {
        if (active?.() === false) return;
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        $cleanup(() => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        });
    });
};
