import Link from "@client/Design/Link/Link";
import { boxShadow, color, withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX } from "solid-js";
import { Dynamic } from "solid-js/web";

interface Props extends AttributeStyleProps {
    ref?: (r: HTMLElement) => void;
    children?: JSX.Element;
    variant?: "primary" | "primaryMuted" | "secondary";
    className?: string;
    onClick?: (ev) => void;
    to?: string;
}

const Pill: Component<Props> = (props) => {
    const {
        children,
        variant = "primary",
        to,
        className,
        onClick,
        ...other
    } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    const _component = () => {
        if (to != null) {
            return Link;
        } else if (onClick != null) {
            return "button";
        } else {
            return "div";
        }
    };
    const component = $memo(_component());

    return (
        <Dynamic
            component={component}
            class={cn(
                Container,
                classes,
                variant,
                { clickable: !!to || !!onClick },
                className
            )}
            onClick={onClick}
            data-cy="pill"
            style={style}
            to={to}
            {...rest}>
            {children}
        </Dynamic>
    );
};

const Container = css`
    display: inline-flex;
    min-height: var(--line-height);
    padding: 1px 12px 3px;
    border-radius: 8px;
    user-select: none;
    text-decoration: none !important;
    white-space: nowrap;
    ${boxShadow(0, -1, 0, "rgba(0, 0, 0, 0.2)", { inset: true })}
    &, &:visited, &:active, &:hover {
        color: ${color("colors/text-600b")};
    }
    //transition: background-color 0.5s ease-in-out;

    &.primary {
        background: ${color("colors/primary-500")};
    }
    &.primaryMuted {
        background: ${color("colors/primary-300")};
        color: ${color("colors/text-300a")};
    }
    &.secondary {
        background: ${color("colors/secondary-500")};
    }

    &.clickable {
        &:hover,
        &:focus {
            &.primary,
            &.primaryMuted {
                background: ${color("colors/primary-600")};
            }
            &.secondary {
                background: ${color("colors/secondary-600")};
            }
            cursor: pointer;
        }
    }
`;

export default withStyle(Pill, { Container });
