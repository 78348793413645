import PlainButton from "@client/Design/Button/PlainButton";
import IconText from "@client/Design/IconText.solid";
import Link from "@client/Design/Link/Link";
import Separator from "@client/Design/Separator/Separator";
import Text from "@client/Design/Text";
import { useAppCtx } from "@client/context/AppContext";
import {
    breakpointUntil,
    color,
    heading1Text,
    heading3Text,
    primaryFontBold,
    style,
} from "@client/style/commonStyle";
import { trpc } from "@client/trpc/trpcClientUtil";
import HeaderModal from "@client/userModal/HeaderModal.solid";
import { UserRole } from "@core/sharedEnums";
import { setCookie } from "@core/util/typescript/cookieUtil";
import { css } from "@linaria/core";
import { userHasRole } from "@server/auth/userRoles";
import cn from "classnames";
import { Component } from "solid-js";

interface Props {
    ref?: HTMLDivElement | ((ref: HTMLDivElement) => void);
    onClose?: () => void;
}

const UserModal: Component<Props> = (props) => {
    const { onClose } = $destructure(props);

    const apiCtx = trpc.useContext();
    const appCtx = useAppCtx();
    const user = $derefSignal(appCtx.loggedInUser)!;

    let pendingMessages = $derefSignal(appCtx.pendingMessages);
    const [, setLoggedInUser] = appCtx.loggedInUser;

    const handleLogout = async () => {
        setCookie("accessToken", undefined);
        await apiCtx.invalidate();
        setLoggedInUser(undefined);
        pendingMessages = false;
    };

    return (
        <HeaderModal ref={props.ref}>
            <Text className={Username} data-cy="user-modal-name">
                {user.username}
            </Text>
            <Separator />

            <div class={ActionList}>
                <Link
                    className={ActionRowLink}
                    to={`/community/user/${user.username}`}
                    onClick={onClose}
                    data-cy="profile-site-action">
                    <Text className={ActionText}>Profile</Text>
                    <IconText icon="person" />
                </Link>
                <Link
                    className={ActionRowLink}
                    to={`/community/user/${user.username}/study-lists/starred`}
                    onClick={onClose}
                    data-cy="profile-study-list-action">
                    <Text className={ActionText}>Study lists</Text>
                    <IconText icon="menu" />
                </Link>
                <Link
                    className={cn(ActionRowLink, {
                        [newActivityIconHighlightStyle]: pendingMessages,
                    })}
                    to={`/community/messages`}
                    onClick={onClose}
                    data-cy="messages-action">
                    <Text className={ActionText}>Messages</Text>
                    <IconText icon="email" />
                </Link>
                <Link
                    className={ActionRowLink}
                    to={`/settings`}
                    onClick={onClose}
                    data-cy="settings-action">
                    <Text className={ActionText}>Settings</Text>
                    <IconText icon="cog" />
                </Link>

                {userHasRole(user, UserRole.Admin) && (
                    <>
                        <Link
                            className={ActionRowLink}
                            to={`/dictionary/new/sentence`}
                            onClick={onClose}
                            data-cy="sentence-action">
                            <Text className={ActionText}>Create sentence</Text>
                            <IconText icon="pencil" />
                        </Link>
                        <Link
                            className={ActionRowLink}
                            to={`/dictionary/new/lesson`}
                            onClick={onClose}
                            data-cy="lesson-action">
                            <Text className={ActionText}>Create lesson</Text>
                            <IconText icon="pencil" />
                        </Link>
                    </>
                )}

                <PlainButton
                    className={ActionRowButton}
                    onClick={handleLogout}
                    data-cy="logout-action">
                    <Text className={ActionText}>Logout</Text>
                    <IconText icon="exit" />
                </PlainButton>
            </div>
        </HeaderModal>
    );
};

const Username = css`
    width: 100%;
    display: inline-block;
    text-align: right;
    white-space: nowrap;
    margin: 0 0 8px;
    color: ${color("colors/primary-800")};

    ${primaryFontBold}
    ${heading3Text}
  ${breakpointUntil("s")} {
        ${heading1Text}
    }
`;

const ActionList = css``;

const ActionRowStyle = style`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none !important;
  white-space: nowrap;
  margin-top: 8px;

  &, &:hover, &:active, &:visited {
    color: ${color("colors/primary-800")};
  }

  ${breakpointUntil("s")} {
    ${heading3Text}
  }
`;

const ActionRowButton = css`
    ${ActionRowStyle}
`;

const ActionRowLink = css`
    ${ActionRowStyle}
`;

const newActivityIconHighlightStyle = css`
    @keyframes flashActivityColor {
        0% {
            color: ${color("colors/primary-800")};
        }
        100% {
            color: ${color("colors/primary-800")};
        }
    }
    animation-name: flashActivityColor;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
`;

const ActionText = css`
    flex: 1;
    text-align: right;
    margin-right: 8px;
`;

export default UserModal;
