import type { UserAuthSchema } from "@server/entities/UserEnt";
import { createContext, createSignal, Signal, useContext } from "solid-js";

const drawerOpen = createSignal(false);
const pendingMessages = createSignal(false);
const seekbarVisible = createSignal(false);
const scrollLocked = createSignal(false);
const loggedInUser = createSignal<UserAuthSchema | undefined>();
const theme = createSignal<Theme>("dark");
const online = createSignal(true);

export type Theme = "light" | "dark";

export const defaultAppContext = {
    drawerOpen,
    // pendingMessages: [{state: "unresolved", loading: false, error: undefined, latest: undefined}, () => void] as [Resource<boolean>, Setter<boolean>],
    // pendingMessages: null as any as [Resource<boolean>, Setter<boolean>],
    pendingMessages,

    seekbarVisible,
    scrollLocked,

    timezone: undefined as string | undefined,

    theme,
    loggedInUser: loggedInUser as any as Signal<UserAuthSchema | undefined>,
    cookies: null as Record<string, string> | null,
    online,
};

export const AppContext = createContext(defaultAppContext);

export const useAppCtx = () => useContext(AppContext);
export const useTheme = () => useContext(AppContext).theme[0]();
