import { color, style, withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX, ValidComponent } from "solid-js";

interface Props extends AttributeStyleProps {
    children: JSX.Element;
    className?: string;
    variant?:
        | "primaryRegular"
        | "primaryLight"
        | "primaryDark"
        | "secondaryRegular";
    component?: ValidComponent;
    onClick?: () => void;
}

const Highlight: Component<Props> = (props) => {
    const {
        component = "span",
        children,
        className,
        variant = "primaryRegular",
        ...other
    } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <Dynamic
            component={component}
            class={cn(_Highlight, className, variant, classes, {
                clickable: rest.onClick != null,
            })}
            style={style}
            {...rest}>
            {children}
        </Dynamic>
    );
};

const selection = (color: string, background: string) => style`
  &::selection, &::-moz-selection {
    color: ${color};
    background: ${background};
  }
`;

const _Highlight = css`
    @layer root {
        display: inline-block;
        padding: 0 4px;
        border-radius: 4px;
        ${selection(color("colors/text-600b"), color("colors/primary-700"))}
        &.primaryRegular {
            background: ${color("highlight/bg")};
            color: ${color("colors/text-600a")};
        }
        &.secondaryRegular {
            background: ${color("colors/secondary-300")};
            color: ${color("colors/text-600a")};
        }
        &.primaryLight {
            background: ${color("highlight/bg-light")};
            color: ${color("colors/text-600a")};
        }
        &.primaryDark {
            background: ${color("highlight/bg-dark")};
            color: ${color("colors/text-600b")};
        }
        &.clickable {
            cursor: pointer;
        }
    }
`;

export default withStyle(Highlight, { Container: _Highlight });
