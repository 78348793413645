import { variableTextStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { ComponentProps, JSX, ValidComponent } from "solid-js";
import { Dynamic } from "solid-js/web";

type Props<Component extends ValidComponent> = AttributeStyleProps &
    ComponentProps<Component> & {
        children?: JSX.Element;
        component?: Component;
        className?: string;
        inline?: boolean;
        onClick?: (ev) => void;
    };

const Text = <Component extends ValidComponent = "span">(
    props: Props<Component>
) => {
    const {
        children,
        className,
        component = "span",
        inline,
        ...other
    } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    return (
        <Dynamic
            component={component}
            class={cn(
                fontAlignmentFixStyle,
                classes,
                variableTextStyle,
                className,
                { [inlineStyle]: inline }
            )}
            style={style}
            {...rest}>
            {children}
        </Dynamic>
    );
};

const fontAlignmentFixStyle = css`
    top: var(--font-adjustment);
`;

const inlineStyle = css`
    @layer c {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
    }
`;

export default Text;
