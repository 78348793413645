import CurrentUserProfilePicture from "@client/community/CurrentUserProfilePicture.solid";
import { AppContext, useAppCtx } from "@client/context/AppContext";
import {
    breakpointFrom,
    breakpointUntil,
    color,
    dropShadow,
} from "@client/style/commonStyle";
import UserModal from "@client/userModal/UserModal.solid";
import { useOnClickOutside } from "@core/util/solid/useOnClickOutside.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, Show, Suspense, useContext } from "solid-js";
import { lazy } from "solid-lazy-plus";

const LoginModal = lazy(() => import("@client/userModal/LoginModal"));

interface Props {
    hidden?: boolean;
}

const HeaderUser: Component<Props> = (props) => {
    const { hidden } = $destructure(props);
    const ctx = useAppCtx();
    const user = $derefSignal(ctx.loggedInUser);

    let isModalOpen = $signal(false);
    const appCtx = useContext(AppContext);
    const pendingMessages = $derefSignal(appCtx.pendingMessages);

    let modalRef = $signal<HTMLDivElement | undefined>();
    let userIconRef: HTMLImageElement | undefined;

    useOnClickOutside(
        () => [modalRef, userIconRef],
        () => {
            isModalOpen = false;
        },
        () => isModalOpen
    );

    return (
        <div class={cn(ModalContainer, { hidden })}>
            <Suspense>
                <Suspense fallback={<div class={ProfilePicturePlaceholder} />}>
                    <CurrentUserProfilePicture
                        ref={(r) => {
                            userIconRef = r;
                        }}
                        className={ProfilePicture}
                        onClick={() => {
                            isModalOpen = !isModalOpen;
                        }}
                        data-cy="user-profile-icon"
                    />
                </Suspense>
                <Suspense>
                    <Show when={pendingMessages}>
                        <div class={NewActivityBadge} />
                    </Show>
                </Suspense>
                <Show when={isModalOpen && !user}>
                    <Suspense>
                        <LoginModal
                            ref={(r) => {
                                modalRef = r;
                            }}
                            onClose={() => {
                                isModalOpen = false;
                            }}
                        />
                    </Suspense>
                </Show>
                <Show when={isModalOpen && user}>
                    <UserModal
                        ref={(r) => {
                            modalRef = r;
                        }}
                        onClose={() => {
                            isModalOpen = false;
                        }}
                    />
                </Show>
            </Suspense>
        </div>
    );
};

const ModalContainer = css`
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 48px;
    height: 48px;
    ${breakpointFrom("s")} {
        margin-left: 16px;
    }
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    transition-delay: 200ms;
    ${breakpointUntil("s")} {
        &.hidden {
            opacity: 0;
            transition-delay: 0ms;
        }
    }
`;

const ProfilePicture = css`
    width: 48px;
    height: 48px;
    border: 4px solid ${color("colors/primary-400")};
    cursor: pointer;
`;

const ProfilePicturePlaceholder = css`
    width: 48px;
    height: 48px;
`;

const NewActivityBadge = css`
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 10;
    top: -4px;
    right: -4px;
    border-radius: 16px;
    background: ${color("colors/primary-600")};
    ${dropShadow(0, 2, 4, "rgba(0, 0, 0, 0.3)")}
`;

export default HeaderUser;
