import {UserRole} from "@core/sharedEnums";


interface User {
    roles: UserRole[];
}

export const userHasRole = (user: User | undefined, role: UserRole) => {
    if (!user) return false;
    return user.roles.includes(role);
};

const roleToNumber = (role: UserRole) => {
    switch (role) {
        case UserRole.SupporterStudent: return 1;
        case UserRole.SupporterSponsor: return 2;
        case UserRole.SupporterEnthusiast: return 3;
        case UserRole.SupporterEmperor: return 4;
        case UserRole.SupporterFamily: return 5;
        case UserRole.Admin: return 99;
        default: return 0;
    }
}

export const userMinHasRole = (user: User | undefined, role: UserRole) => {
    if (!user) return false;
    const roleNumber = roleToNumber(role);
    return user.roles.some(r => roleToNumber(r) >= roleNumber);
};