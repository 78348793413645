import IconText from "@client/Design/IconText.solid";
import Separator from "@client/Design/Separator/Separator";
import { boxShadow, color, withStyle } from "@client/style/commonStyle";
import {
    AttributeStyleProps,
    useAttributeStyle,
} from "@core/util/solid/attributeStyle.solid";
import { useOnClickOutside } from "@core/util/solid/useOnClickOutside.solid";
import { css } from "@linaria/core";
import cn from "classnames";
import { Component, JSX } from "solid-js";

interface Props extends AttributeStyleProps {
    items: (JSX.Element | undefined)[];
    selectedItem?: JSX.Element;
    selectedItemIdx: number;
    className?: string;

    onSelect?: (idx: number) => void;
}

const Dropdown: Component<Props> = (props) => {
    const {
        items,
        selectedItem,
        selectedItemIdx,
        className,
        onSelect,
        ...other
    } = $destructure(props);
    const { classes, style, rest } = $destructure(useAttributeStyle(other));

    let containerRef!: HTMLDivElement;

    let open = $signal(false);

    const handleClick = (ev, idx: number) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (idx == selectedItemIdx) return;
        onSelect?.(idx);
        open = false;
    };

    useOnClickOutside(
        () => [containerRef],
        () => {
            open = false;
        },
        () => open
    );

    return (
        <div
            ref={containerRef}
            class={cn(classes, className, { open })}
            style={style}
            {...rest}>
            <div
                class={CurrentOption}
                onClick={() => {
                    open = !open;
                }}>
                {selectedItem ?? items[selectedItemIdx]}
                <IconText icon="down-arrow" />
            </div>

            <div class={cn(Options, { open })}>
                <For each={items}>
                    {(item, idx) => (
                        <Show when={item}>
                            {idx() != 0 && <Separator />}
                            <div
                                class={cn(Option, {
                                    [selectedOptionStyle]:
                                        idx() == selectedItemIdx,
                                })}
                                onClick={(ev) => handleClick(ev, idx())}>
                                {item}
                            </div>
                        </Show>
                    )}
                </For>
            </div>
        </div>
    );
};

const CurrentOption = css`
    display: flex;
    gap: 4px;
    padding: 5px 8px 7px 12px;
    border-radius: 4px;
    background: ${color("highlight/bg")};
    cursor: pointer;
`;

const Options = css`
    position: absolute;
    top: 100%;
    flex-direction: column;
    min-height: 16px;
    display: none;
    z-index: 9998;
    background: ${color("colors/primary-50")};
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    ${boxShadow(0, 1, 8, "rgba(0, 0, 0, 0.2)")}
    ${Separator.styles.Container} {
        padding: 0 8px;
    }

    ${Separator.styles.Line} {
        border-color: ${color("dropdown/separator")};
    }

    &.open {
        display: flex;
    }
`;

const Option = css`
    cursor: pointer;
    user-select: none;
    padding: 8px 20px;
    white-space: nowrap;
`;

const selectedOptionStyle = css`
    color: ${color("colors/text-900a")};
    background: ${color("colors/primary-200")};
`;

export default withStyle(Dropdown, {
    CurrentOption,
    Options,
    Option,
    SelectedOption: selectedOptionStyle,
});
